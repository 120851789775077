import { useTranslation } from "react-i18next";
import { Accordion, Col, Container, Form, Row } from "react-bootstrap";
import "../../assets/Styles/Common.css";
import styles from "../Profile/Profile.module.css";
import { useForm, Controller } from "react-hook-form";
import { useState, useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { sanitizeHtmlTags } from "../../utils/sanitizeHtmlTags";
import { useNavigate } from "react-router-dom";
import { updateIsRedirect } from "../../store/slices/createJob";
import { useDispatch, useSelector } from "react-redux";
import SettingSidebar from "../Setting/SettingSidebar";
import UploadProfile from "../Setting/UploadProfile";
import { STATUS_CODES } from "../../utils/StatusCode";
import { STATUS_MSG } from "../../utils/StatusMsg";
import Toster from "../../utils/Toaster";
import {
  updateUserDetails,
  userDetails,
  userLogoutClear,
} from "../../store/slices/UserSlice";
import Loader from "../../utils/Loader/Loader";
import profile from "../../assets/Images/user.png";
import { AiFillCamera } from "react-icons/ai";
import { UserSettingAccordian } from "../../mockData";
import Confirm from "../CreateJob/Furnitures/Modals/confirm";
import SublyApi from "../../helpers/Api";

//---------function for profile page---------
function Profile() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    setValue,
    control,
    clearErrors,
    formState: { errors },
  } = useForm();

  const [phoneNo, setPhoneNo] = useState("");
  const [dialCode, setDialCode] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const { userToken, isLoading } = useSelector((state) => state.user);
  const [profilePreview, setProfilePreview] = useState(profile);
  const [profileImage, setProfileImage] = useState("");
  const [responseData, setResponseData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  // const [eventKeyValue, setEventKeyValue] = useState(1);
  // const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  // window.addEventListener("resize", function (event) {
  //   setScreenWidth(window.innerWidth);
  // });

  // disable space on key press input
  const handleKeyPress = (e, t) => {
    if (e.target.value == " ") setValue(e.target.name, e.target.value.trim());
  };

  // function for apply validation in phoneNumber field
  const validateNumber = () => {
    if (!phoneNo) {
      return `${t("CONTACT_REQUIRED")}`;
    } else {
      const minPhoneNumberLength = 5;
      const maxPhoneNumberLength = 15;
      if (phoneNo.length < minPhoneNumberLength) {
        return `${t("PHONE_MINLENGTH")}`;
      }
      if (phoneNo.length > maxPhoneNumberLength) {
        return `${t("PHONE_MAXLENGTH")}`;
      }
      return true;
    }
  };

  //--------function for get user details api calling--------
  const getProfileDetails = () => {
    dispatch(userDetails(userToken)).then((responsejson) => {
      const response = responsejson?.payload;
      const msg = response && response?.data && response?.data?.message;
      const ResponseCode =
        STATUS_MSG[response && response?.data && response?.data?.code];
      if (response && response?.code === STATUS_CODES?.SUCCESS) {
        setResponseData(response?.data);
        setValue(
          "firstName",
          response && response.data
            ? response.data?.first_name.charAt(0).toUpperCase() +
                response.data?.first_name.slice(1).toLowerCase()
            : ""
        );
        setValue(
          "lastName",
          response && response.data
            ? response.data?.last_name.charAt(0).toUpperCase() +
                response.data?.last_name.slice(1).toLowerCase()
            : ""
        );
        setValue(
          "email",
          response && response.data ? response?.data?.email : ""
        );
        setDialCode(response?.data?.dial_code ? response?.data?.dial_code : "");
        setCountryCode(
          response && response?.data ? response?.data?.country_code : ""
        );
        setPhoneNo(
          response?.data?.phone_number ? response?.data?.phone_number : ""
        );
        setProfilePreview(
          response?.data && response?.data?.profile_image !== null
            ? response?.data?.profile_image
            : profile
        );
      } else if (
        response &&
        response?.data &&
        response?.data?.code === STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
        navigate("/");
      } else {
        if (
          response &&
          response?.data &&
          response?.data?.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else if (
          response &&
          response?.data &&
          response?.data?.code === STATUS_CODES.FAILED_TO_ACCESS
        ) {
          Toster(t(ResponseCode), "error");
          dispatch(userLogoutClear());
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  useEffect(() => {
    getProfileDetails();
  }, []);

  //--------function for update user details api calling--------
  const onSubmit = async (formdata) => {
    let requestData = new FormData();
    requestData.append(
      "first_name",
      formdata ? formdata.firstName && formdata.firstName.trim() : ""
    );
    requestData.append(
      "last_name",
      formdata ? formdata.lastName && formdata.lastName.trim() : ""
    );
    requestData.append(
      "email",
      formdata ? formdata.email && formdata.email.trim() : ""
    );
    if (dialCode && !dialCode.includes("+")) {
      requestData.append("dial_code", `+${dialCode}`);
    } else {
      requestData.append("dial_code", dialCode);
    }
    requestData.append("country_code", countryCode ? countryCode : "");
    requestData.append("phone_number", phoneNo ? phoneNo : "");
    requestData.append("profile_image", profileImage ? profileImage : "");
    const data = { requestData: requestData, userToken: userToken };
    dispatch(updateUserDetails(data)).then((responsejson) => {
      const response = responsejson.payload;
      const msg = response && response.data && response.data.message;
      const ResponseCode =
        STATUS_MSG[response && response.data && response.data.code];
      if (response && response.code === STATUS_CODES.SUCCESS) {
        Toster(t("Profile update successfully"), "success");
        getProfileDetails();
      } else if (
        response &&
        response.data &&
        response.data.code === STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
        navigate("/driver-login");
      } else {
        if (
          response &&
          response.data &&
          response.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  //----- function for select profile image-----
  function onImageChange(e) {
    if (e.target.files.length !== 0) {
      const file = e.target.files[0];
      const fileSizeInMB = (file.size / (1024 * 1024)).toFixed(2);
      if (fileSizeInMB > 5) {
        Toster(t("FILE_SIZE_LARGE"), "error");
        return;
      }
      setProfilePreview(URL.createObjectURL(file));
      setProfileImage(file);
    }
  }

  // disable space on key press input email field
  const onInputChange = (e) => {
    setValue(e.target.name, e.target.value.replace(/\s/g, "")); // this remove from all string
  };

  // const handleHeaderClick = (item) => {
  //   const navLink = item.link;
  //   const itemType = item.type;
  //   if (itemType == "anotherRoute") {
  //     navigate(navLink);
  //   } else if (itemType == "modal") {
  //     if (navLink == "delete-account") {
  //       setShowDeleteModal(true);
  //     }
  //     if (navLink == "logout") {
  //       setShowLogoutModal(true);
  //     }
  //   } else {
  //     return;
  //   }
  // };

  //----- function for delete customer account-----
  const deleteCustomerAccount = async () => {
    setLoading(true);
    await SublyApi.deleteCustomerAccount(userToken).then((response) => {
      const msg = response && response.data && response.data.message;
      const ResponseCode =
        STATUS_MSG[response && response.data && response.data.code];
      if (response && response.code === STATUS_CODES.SUCCESS) {
        setLoading(false);
        setShowDeleteModal(false);
        Toster(t("DELETE_ACCOUNT_SUCCESS"), "success");
        dispatch(userLogoutClear());
        dispatch(updateIsRedirect(false));
        navigate("/login");
      } else if (
        response &&
        response.data &&
        response.data.code === STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
        navigate("/login");
        setLoading(false);
      } else {
        setLoading(false);
        if (
          response &&
          response.data &&
          response.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  const handleConfirm = () => {
    deleteCustomerAccount();
  };

  const handleCancel = () => {
    setShowDeleteModal(false);
  };

  const handleLogoutConfirm = () => {
    dispatch(userLogoutClear());
    dispatch(updateIsRedirect(false));
    navigate("/");
  };

  const handleLogoutCancel = () => {
    setShowLogoutModal(false);
  };

  const ProfileSection = () => {
    return (
      <>
        <Col xs={12} sm={12} md={12} lg={6}>
          <div className={styles.profileSettingsSection}>
            <h3 className={`${styles.profileHeading} profileHeadingBotLine`}>
              {t("EDIT_PROFILE")}
            </h3>
            <h4 className={styles.userInfo}>{t("USER_INFO")}</h4>
            <Form onSubmit={handleSubmit(onSubmit)} className="formSection">
              <Form.Group className="mb-3">
                <Form.Label>{t("FIRST_NAME")}</Form.Label>
                <Form.Control
                  placeholder={`${t("FIRSTNAME_TEXT")}`}
                  type="text"
                  {...register("firstName", {
                    onChange: (e) => handleKeyPress(e),
                    required: {
                      value: true,
                      message: `${t("FIRSTNAME_REQUIRED")}`,
                    },
                    minLength: {
                      value: 2,
                      message: `${t("FIRSTNAME_MINLENGTH")}`,
                    },
                    maxLength: {
                      value: 50,
                      message: `${t("FIRSTNAME_MAXLENGTH")}`,
                    },
                    ...sanitizeHtmlTags(),
                  })}
                />
                {errors.firstName && (
                  <span className="errorMsg">{errors.firstName.message}</span>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>{t("LAST_NAME")}</Form.Label>
                <Form.Control
                  placeholder={`${t("LASTNAME_TEXT")}`}
                  type="text"
                  {...register("lastName", {
                    onChange: (e) => handleKeyPress(e),
                    required: {
                      value: true,
                      message: `${t("LASTNAME_REQUIRED")}`,
                    },
                    minLength: {
                      value: 2,
                      message: `${t("LASTNAME_MINLENGTH")}`,
                    },
                    maxLength: {
                      value: 50,
                      message: `${t("LASTNAME_MAXLENGTH")}`,
                    },
                    ...sanitizeHtmlTags(),
                  })}
                />
                {errors.lastName && (
                  <span className="errorMsg">{errors.lastName.message}</span>
                )}
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>{t("EMAIL")}</Form.Label>
                <Form.Control
                  placeholder={`${t("EMAIL_TEXT")}`}
                  type="text"
                  {...register("email", {
                    onChange: (e) => onInputChange(e),
                    pattern: {
                      value:
                        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: `${t("EMAIL_INVALID")}`,
                    },
                    minLength: {
                      value: 5,
                      message: `${t("EMAIL_MINLENGTH")}`,
                    },
                    maxLength: {
                      value: 100,
                      message: `${t("EMAIL_MAXLENGTH")}`,
                    },
                    ...sanitizeHtmlTags(),
                    required: {
                      value: true,
                      message: `${t("EMAIL_REQUIRED")}`,
                    },
                  })}
                />
                {errors.email && (
                  <span className="errorMsg">{errors.email.message}</span>
                )}
              </Form.Group>
              <Form.Group className="mb-3 phoneInputSet">
                <Form.Label>{t("CONTACT")}</Form.Label>
                <Controller
                  control={control}
                  name="phoneNumber"
                  render={({ field }) => (
                    <PhoneInput
                      placeholder={`${t("CONTACT_TEXT")}`}
                      country={"gb"}
                      countryCodeEditable={false}
                      value={dialCode?.toString() + phoneNo?.toString()}
                      onChange={(value, country) => {
                        let dialCode = country?.dialCode;
                        let phone = value?.slice(
                          dialCode?.length,
                          value?.length
                        );
                        setCountryCode(country.countryCode);
                        setDialCode(dialCode);
                        setPhoneNo(phone);
                        field.onChange(value);
                        clearErrors("phoneNumber");
                      }}
                    />
                  )}
                  rules={{ validate: validateNumber }}
                />
                {errors.phoneNumber && (
                  <span className="errorMsg">{errors.phoneNumber.message}</span>
                )}
              </Form.Group>
              <div className={styles.uploadSection}>
                <div className={styles.uploadProfile}>
                  <div className={styles.profileImg}>
                    <img src={profilePreview} alt="profile" />
                  </div>
                  <div className={styles.profileInput}>
                    <input
                      accept="image/*"
                      className="inputfile hideDiv"
                      id="file-1"
                      name="profileImage"
                      type="file"
                      style={{ display: "none" }}
                      onChange={(e) => {
                        onImageChange(e);
                      }}
                    />
                    <label htmlFor="file-1" className={styles.uploadPic}>
                      <AiFillCamera />
                    </label>
                  </div>
                </div>
              </div>
              <div className={`${styles.updateBtnSpace}`}>
                <button type="submit">{t("UPDATE")}</button>
              </div>
            </Form>
          </div>
        </Col>
        <Col xs={12} sm={12} md={12} lg={3}>
          <UploadProfile responseData={responseData} />
        </Col>
      </>
    );
  };

  const renderSection = (id) => {
    switch (id) {
      case "1":
        return <ProfileSection />;
      default:
        return <></>;
    }
  };

  return (
    <div className="main">
      {isLoading === true || loading ? <Loader /> : ""}
      <Container>
        <div className="spaceTopManage">
          <section className="midSection">
            <div className={styles.profileSection}>
              <h2>{t("MY_ACCOUNT")}</h2>
              <p></p>
              {/* <p>Lorem Ipsum is simply dummy</p> */}
            </div>

            <div className={styles.profileBox}>
              <Row className={styles.profileSectionRow}>
                <Col xs={12} sm={12} md={12} lg={3}>
                  <SettingSidebar />
                </Col>
                {/* <ProfileSection /> */}
                <Col xs={12} sm={12} md={12} lg={6}>
                  <div className={styles.profileSettingsSection}>
                    <h3
                      className={`${styles.profileHeading} profileHeadingBotLine`}
                    >
                      {t("EDIT_PROFILE")}
                    </h3>
                    <h4 className={styles.userInfo}>{t("USER_INFO")}</h4>
                    <Form
                      onSubmit={handleSubmit(onSubmit)}
                      className="formSection"
                    >
                      <Form.Group className="mb-3">
                        <Form.Label>{t("FIRST_NAME")}</Form.Label>
                        <Form.Control
                          placeholder={`${t("FIRSTNAME_TEXT")}`}
                          type="text"
                          {...register("firstName", {
                            onChange: (e) => handleKeyPress(e),
                            required: {
                              value: true,
                              message: `${t("FIRSTNAME_REQUIRED")}`,
                            },
                            minLength: {
                              value: 2,
                              message: `${t("FIRSTNAME_MINLENGTH")}`,
                            },
                            maxLength: {
                              value: 50,
                              message: `${t("FIRSTNAME_MAXLENGTH")}`,
                            },
                            ...sanitizeHtmlTags(),
                          })}
                        />
                        {errors.firstName && (
                          <span className="errorMsg">
                            {errors.firstName.message}
                          </span>
                        )}
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>{t("LAST_NAME")}</Form.Label>
                        <Form.Control
                          placeholder={`${t("LASTNAME_TEXT")}`}
                          type="text"
                          {...register("lastName", {
                            onChange: (e) => handleKeyPress(e),
                            required: {
                              value: true,
                              message: `${t("LASTNAME_REQUIRED")}`,
                            },
                            minLength: {
                              value: 2,
                              message: `${t("LASTNAME_MINLENGTH")}`,
                            },
                            maxLength: {
                              value: 50,
                              message: `${t("LASTNAME_MAXLENGTH")}`,
                            },
                            ...sanitizeHtmlTags(),
                          })}
                        />
                        {errors.lastName && (
                          <span className="errorMsg">
                            {errors.lastName.message}
                          </span>
                        )}
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label>{t("EMAIL")}</Form.Label>
                        <Form.Control
                          placeholder={`${t("EMAIL_TEXT")}`}
                          type="text"
                          {...register("email", {
                            onChange: (e) => onInputChange(e),
                            pattern: {
                              value:
                                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                              message: `${t("EMAIL_INVALID")}`,
                            },
                            minLength: {
                              value: 5,
                              message: `${t("EMAIL_MINLENGTH")}`,
                            },
                            maxLength: {
                              value: 100,
                              message: `${t("EMAIL_MAXLENGTH")}`,
                            },
                            ...sanitizeHtmlTags(),
                            required: {
                              value: true,
                              message: `${t("EMAIL_REQUIRED")}`,
                            },
                          })}
                        />
                        {errors.email && (
                          <span className="errorMsg">
                            {errors.email.message}
                          </span>
                        )}
                      </Form.Group>
                      <Form.Group className="mb-3 phoneInputSet">
                        <Form.Label>{t("CONTACT")}</Form.Label>
                        <Controller
                          control={control}
                          name="phoneNumber"
                          render={({ field }) => (
                            <PhoneInput
                              placeholder={`${t("CONTACT_TEXT")}`}
                              country={"gb"}
                              countryCodeEditable={false}
                              value={dialCode?.toString() + phoneNo?.toString()}
                              enableLongNumbers={true}
                              onChange={(value, country) => {
                                let dialCode = country.dialCode;
                                let phone = value.slice(
                                  dialCode.length,
                                  value.length
                                );
                                setCountryCode(country.countryCode);
                                setDialCode(dialCode);
                                setPhoneNo(phone);
                                field.onChange(value);
                                clearErrors("phoneNumber");
                              }}
                            />
                          )}
                          rules={{ validate: validateNumber }}
                        />
                        {errors.phoneNumber && (
                          <span className="errorMsg">
                            {errors.phoneNumber.message}
                          </span>
                        )}
                      </Form.Group>
                      <div className={styles.uploadSection}>
                        <div className={styles.uploadProfile}>
                          <div className={styles.profileImg}>
                            <img src={profilePreview} alt="profile" />
                          </div>
                          <div className={styles.profileInput}>
                            <input
                              accept="image/*"
                              className="inputfile hideDiv"
                              id="file-1"
                              name="profileImage"
                              type="file"
                              style={{ display: "none" }}
                              onChange={(e) => {
                                onImageChange(e);
                              }}
                            />
                            <label
                              htmlFor="file-1"
                              className={styles.uploadPic}
                            >
                              <AiFillCamera />
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className={`${styles.updateBtnSpace}`}>
                        <button type="submit">{t("UPDATE")}</button>
                      </div>
                    </Form>
                  </div>
                </Col>
                <Col xs={12} sm={12} md={12} lg={3}>
                  <UploadProfile responseData={responseData} />
                </Col>
              </Row>

              {/* if want to add accordian on responsiveness use this code*/}

              {/* {screenWidth > 991 ? (
                <Row>
                  <Col xs={12} sm={12} md={12} lg={3}>
                    <SettingSidebar />
                  </Col>
                  <ProfileSection />
                </Row>
              ) : (
                <>
                  <h5 className={styles.profileHeading}>{t("SETTINGS")}</h5>
                  <Accordion
                    defaultActiveKey={eventKeyValue}
                    onSelect={(value) => {
                      value && setEventKeyValue(value);
                    }}
                    className="sideBarAccordian"
                  >
                    {UserSettingAccordian.length > 0
                      ? UserSettingAccordian.map((item, index) => (
                          <Accordion.Item eventKey={item.id} key={item.id}>
                            <Accordion.Header
                              onClick={() => handleHeaderClick(item)}
                            >
                              {item.text}
                            </Accordion.Header>
                            {item.type !== "modal" && (
                              <Accordion.Body>
                                {renderSection(item.id)}
                              </Accordion.Body>
                            )}
                          </Accordion.Item>
                        ))
                      : ""}
                  </Accordion>
                </>
              )} */}
            </div>
          </section>
        </div>

        {/* alert modal popup for delete account*/}
        <Confirm
          show={showDeleteModal}
          subTitle={t("DELETE_ALERT") + " " + t("DELETYETITLE")}
          handleConfirm={handleConfirm}
          handleCancel={handleCancel}
        />

        {/* alert modal popup for user logout*/}
        <Confirm
          show={showLogoutModal}
          subTitle={t("DELETE_ALERT") + " " + t("LOGOUTTITLE")}
          handleConfirm={handleLogoutConfirm}
          handleCancel={handleLogoutCancel}
        />
      </Container>
    </div>
  );
}
export default Profile;

export const STATUS_MSG = {
  100: "HEADER_IS_MISSING",
  101: "DEVICE_TYPE_NOT_ALLOWED",
  102: "INVALID_API_KEY",
  103: "TOKEN_REQUIRE_AUTHENTICATION",
  104: "INVALID_TOKEN",
  105: "SERVER_VALIDATION",
  107: "USER_NOT_FOUND",
  108: "ACCOUNT_BLOCKED",
  109: "SOCIAL_USER_NOT_FOUND",
  110: "FAILED_TO_ACCESS",
  200: "Success",
  500: "Internal server error",
  111: "INVALID_LOGIN_CREDENTIAL",
  112: "ACCOUNT_NOT_FOUND",
  113: "ACCOUNT_INACTIVE",
  114: "ACCOUNT_DELETED",
  115: "OLD_PASSWORD_DOES_NOT_MATCH",
  117: "EMAIL_ALREADY_IN_USE",
  118: "PHONE_NUMBER_EXIST",
  119: "SOCIALID_EXIST",
  120: "EMAIL_LINKED_SOCIALUSER",
  121: "EMAIL_LINKED_NORMALUSER",
  123: "EMAIL_FAIL",
  124: "VEHICLE_IMG",
  125: "LICENSE_IMG",
  126: "GT_INSURANCE_IMG",
  127: "HR_INSURANCE_IMG",
  128: "PL_INSURANCE_IMG",
  129: "VEHICLE_IMG_SIZE",
  130: "INVALID_VEHICLE_IMG",
  131: "INVALID_VEHICLE_ID",
  132: "INVALID_COLOR_ID",
  133: "CODE_EXIST",
  134: "CODE_NOT_MATCH",
  135: "LICENSE_IMG_SIZE",
  136: "INVALID_LICENSE_IMG",
  137: "GT_INSURANCE_IMG_SIZE",
  138: "INVALID_GT_INSURANCE_IMG",
  139: "HR_INSURANCE_IMG_SIZE",
  140: "INVALID_HR_INSURANCE_IMG",
  141: "PL_INSURANCE_IMG_SIZE",
  142: "INVALID_PL_INSURANCE_IMG",
  143: "FAILED_IMG",
  144: "FAILED_LICENSE_IMG",
  145: "FAILED_GT_IMG",
  146: "FAILED_HR_IMG",
  147: "FAILED_PL_IMG",
  148: "INVALID_CATEGORY_ID",
  149: "INVALID_VEHICLETYPE_ID",
  150: "INVALID_DRIVER_ID",
  151: "INVALID_SOURSE_FLOOR_ID",
  152: "INVALID_DESTINATION_FLOOR_ID",
  153: "INVALID_HELPER_ID",
  154: "INVALID_CATEGORY_JSON",
  155: "WRONG",
  156: "INVALID_UNITID",
  157: "INVALID_STOP",
  158: "INVALID_SPROPERTYID",
  159: "INVALID_DPROPERTYID",
  160: "INVALID_SHEDULE_DATA:",
  161: "TIME_EXIST",
  162: "CUSTOMER_NOT_PERMIT",
  163: "INVALID_PRICE",
  164: "FOR_SCHEDULE",
  165: "DRIVER_NOT_PERMISION",
  166: "CAN_NOT_DELETE",
  167: "ALEREADY_LICENCE_USE",
  168: "PROFILE_REQUIRED",
  169: "PROFILE_IMG_SIZE",
  170: "INVALID_PROFILE",
  171: "DOC_IMG_REQUIRED",
  172: "DOC_IMG_SIZE",
  173: "INVALID_DOC_IMG",
  174: "INVALID_DOC",
  175: "PASS_NOT_MATCH",
  176: "NOTIFY_ALREADY_EXIST",
  177: "ADD_VEHICLE",
  178: "VEHICLE_NAME_ALREDY",
  179: "NOT_PERMIT_USER",
  180: "VEHICLE_TYPE_NOT_DELETE",
  181: "VEHICLE_TYPE_STATUS",
  182: "INVALID_KEY",
  183: "MASTER_DATA_EXIST",
  184: "INVALID_MASTER_DATA",
  185: "MASTER_DATA_NOT_DELETE",
  186: "MASTER_DATA_STATUS",
  187: "INVALID_JOBID",
  188: "REQUEST_STATUS",
  189: "NOT_CHANGE_STATUS",
  190: "START_TIME",
  191: "PAYMENT_INTENT",
  192: "AVALABLE_DATE",
  193: "INVALID_COUPON_ID",
  194: "COUPON_ALREADY_EXISTS",
  195: "INVALID_RATING_ID",
  196: "INVALID_COMMISSION_id_type",
  197: "INVALID_ID",
  200: "ESTIMATE_WEIGHT_ID",
  201: "INVALID_JOB_TEMP_ID",
  202: "CATEGORY_ICON",
  203: "CATEGORY_ICON_SIZE",
  204: "INVALID_CATEGORY_ICON",
  205: "CATEGORY_ADD",
  206: "CATEGORY_UPDATE",
  207: "CUSTOMER_EXIST",
  208: "ESTIMATE_WEIGHT_ID",
  209: "DRIVER_EXIST",
  210: "TIME_IS_REQUIRED_FOR_CATEGORY_ITEMS",
  211: "TIME_IS_REQUIRED_FOR_UPDATED_FLOOR",
  212: "DRIVER_DOCUMENT_ALREDAY_REJECTED",
  213: "DRIVER_DOCUMENT_ALREDAY_APPROVED",
  214: "DRIVER_DOCUMENT_ID_INVALID",
  215: "USER_STATUS",
  216: "IMAGE_REQUIRED",
  217: "ALREADY_RATING",
  218: "PAYMENT_SUCCESS",
  219: "PAYMENT_CREATE",
  220: "PAYMENT_INCOMPLETE",
  221: "JOB_INCOMPLETE",
  222: "INVALID_EBQUIRY",
  223: "CANCEL_JOB",
  224: "REFUND_PAYMENT",
  225: "COUPON_ADD_SUCCESS",
  226: "COUPON_UPDATE_SUCCESS",
  227: "COUPON_STATUS_CHANGED_SUCCESS",
  228: "INVALID_COUPON_CODE",
  229: "CUATOM_ITEM_NAME_ALREADY_EXIST",
  230: "PAYMENT_ALREADT_RELEASED",
  231: "INVALID_USER_SERVICE_ID",
  232: "DRIVER_SERVICE_ALREADY_REJECRED",
  233: "DRIVER_SERVICE_ALREADY_APPROVED",
  234: "INVALID_DRIVER_VEHICLE_ID",
  235: "DRIVER_VEHICLE_ALREADY_REJECTED",
  236: "DRIVER_VEHICLE_ALREADY_APPROVED",
  276: "COUPON_CODE_ALREADY_USED",
};

import { useTranslation } from "react-i18next";
import styles from "../../../DRIVERSIDE/appComponents/DriverProfile/DriverProfile.module.css";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaRegEdit } from "react-icons/fa";
import { useEffect, useState } from "react";
import DocumentAlert from "../../authComponent/DriverSignUp/DocumentAlert";
import { Rating } from "@mui/material";
import AddVanPopup from "./AddVanPopup/AddVanPopup";
import { userLogoutClear } from "../../../store/slices/UserSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import SublyApi from "../../../helpers/Api";
import { STATUS_CODES } from "../../../utils/StatusCode";
import Loader from "../../../utils/Loader/Loader";
import { STATUS_MSG } from "../../../utils/StatusMsg";
import Toster from "../../../utils/Toaster";
import profile from "../../../assets/Images/user.png";

//---------function for profile upload---------
function ProfileUpload({ getMainVehicleLists }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [title, setTitle] = useState("");
  const [id, setId] = useState("");
  const [showVanModal, setShowVanModal] = useState(false);
  const [vehicleList, setVehicleList] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const { userToken, updateUser } = useSelector((state) => state.user);
  const [isUpdate, setIsUpdate] = useState(false);
  const [vanList, setVanList] = useState("");
  const [userInfo, setUserInfo] = useState("");
  const [ratingInfo, setRatingInfo] = useState("");

  useEffect(() => {
    setRatingInfo(updateUser.rating);
  }, [updateUser, ratingInfo]);

  //----- function for open van modal popup-----
  const handleVanPopup = (item) => {
    setVanList(item);
    setShowVanModal(true);
  };

  // function for get vehicle list api calling
  async function getVehicleList() {
    setIsLoading(true);
    const details = await SublyApi.getVehicleList(userToken, pageNumber);
    if (details && details.code === STATUS_CODES.SUCCESS) {
      setVehicleList(details && details.data && details.data.vehicleList);
      // setUserInfo(details && details.data);
      setIsLoading(false);
      getMainVehicleLists && getMainVehicleLists();
    } else if (
      details &&
      details.data &&
      details.data.code === STATUS_CODES.INVALID_TOKEN
    ) {
      dispatch(userLogoutClear());
      navigate("/driver-login");
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getVehicleList();
  }, [pageNumber]);

  //----- function for delete van -----
  const onImageRemove = async (id) => {
    setIsLoading(true);
    await SublyApi.deleteVehicle(userToken, id).then((response) => {
      const msg = response && response.data && response.data.message;
      const ResponseCode =
        STATUS_MSG[response && response.data && response.data.code];
      if (response && response.code === STATUS_CODES.SUCCESS) {
        setIsLoading(false);
        Toster(t("VAN_DELETE"), "success");
        getVehicleList();
      } else if (
        response &&
        response.data &&
        response.data.code === STATUS_CODES.INVALID_TOKEN
      ) {
        dispatch(userLogoutClear());
        navigate("/driver-login");
        setIsLoading(false);
      } else {
        setIsLoading(false);
        if (
          response &&
          response.data &&
          response.data.code === STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(msg, "error");
        } else {
          Toster(t(ResponseCode), "error");
        }
      }
    });
  };

  return (
    <>
      {isLoading && <Loader />}
      <div className={styles.uploadSection}>
        <div className={styles.uploadProfile}>
          <div className={styles.profileImg}>
            {(updateUser && updateUser.profile_image == "") ||
            (updateUser && updateUser.profile_image == null) ? (
              <img src={profile} alt="profile" />
            ) : (
              <img src={updateUser && updateUser.profile_image} alt="profile" />
            )}
          </div>

          <div className={styles.userDetails}>
            <h2>
              {updateUser && updateUser.first_name}{" "}
              {updateUser && updateUser.last_name}
            </h2>
            <h4>{updateUser && updateUser.email}</h4>
            <p>
              {updateUser && updateUser.dial_code}{" "}
              {updateUser && updateUser.phone_number}
            </p>
            <span>{`${t("UNIQUE_ID")} : ${updateUser?.unique_id}`}</span>
          </div>
        </div>
        <div className={styles.ratingSection}>
          <h6>
            {t("RATING")}
            <sub>
              {" "}
              ({ratingInfo && Number(ratingInfo?.average_rating)?.toFixed(1)})
            </sub>
          </h6>
          <Rating
            name="read-only"
            value={ratingInfo && ratingInfo?.average_rating}
            precision={0.5}
            readOnly
          />
        </div>
      </div>

      {vehicleList && vehicleList.length > 0 && (
        <h5 className={styles.vehicleInfo}>{t("VEHICLE_INFO")}</h5>
      )}

      {vehicleList &&
        vehicleList.length > 0 &&
        vehicleList.map((vehicle, index) => {
          return (
            <div className={styles.vanDetails} key={index}>
              <div className={styles.vanIcon}>
                <h6>{vehicle.vehicle_name}</h6>
                <div className={styles.vanIcon}>
                  <RiDeleteBin6Line
                    className={styles.vanDelete}
                    onClick={() => {
                      setShowAlertModal(true);
                      setTitle(`${t(`this ${vehicle.vehicle_name} van`)}`);
                      setId(vehicle && vehicle.id);
                    }}
                  />
                  <FaRegEdit
                    onClick={() => {
                      handleVanPopup(vehicle);
                      setIsUpdate(true);
                    }}
                  />
                </div>
              </div>
              <div className={styles.vanIcon}>
                <div>
                  <p>
                    {t("LICENSE_PLATE_NUMBER")} :{" "}
                    <strong>{vehicle && vehicle.licence_plate_number}</strong>
                  </p>
                  <p>
                    {t("COLOR_VEHICLE")} :{" "}
                    <span>{vehicle && vehicle.value_name}</span>
                  </p>
                  <p>
                    {t("YEAR")} : <span>{vehicle && vehicle.year}</span>
                  </p>
                  <p>
                    {t("STATUS")} :{" "}
                    {vehicle && vehicle.status == "pending" && (
                      <span className={styles.inActiveCls}>{t("PENDING")}</span>
                    )}
                    {vehicle && vehicle.status == "approved" && (
                      <span className={styles.activeCls}>{t("APPROVED")}</span>
                    )}
                    {vehicle && vehicle.status == "rejected" && (
                      <span className={styles.inActiveCls}>
                        {t("REJECTED")}
                      </span>
                    )}
                  </p>
                </div>
                <img src={vehicle && vehicle.vehicle_image} alt="vehicle-img" />
              </div>
            </div>
          );
        })}
      <div className={`${styles.updateBtnSpace} commonButton`}>
        <button
          type="button"
          onClick={() => {
            handleVanPopup("");
            setIsUpdate(false);
          }}
        >
          {t("ADD_VAN")}
        </button>

        {/* modal for delete van  */}
        <DocumentAlert
          setShowAlertModal={setShowAlertModal}
          showAlertModal={showAlertModal}
          onImageRemove={onImageRemove}
          title={title}
          id={id}
          isDelete={false}
        />
        {/* modal for add and edit van */}
        <AddVanPopup
          showVanModal={showVanModal}
          setShowVanModal={setShowVanModal}
          isUpdate={isUpdate}
          vanList={vanList}
          getVehicleList={getVehicleList}
        />
      </div>
    </>
  );
}
export default ProfileUpload;

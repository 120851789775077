import "../../../assets/Styles/DriverCommon.css";
import styles from "../DriverSignUp/DriverSignUp.module.css";
import { useTranslation } from "react-i18next";
import { Container, Form } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { NavLink, useLocation } from "react-router-dom";
import { sanitizeHtmlTags } from "../../../utils/sanitizeHtmlTags";
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import backgroundImage from "../../../assets/DriverImages/Bg.png";
import DriverFacebookSocialLogin from "../../../DRIVERSIDE/commonComponents/DriverFacebooklLogin/DriverFacebookSocialLogin";
import DriverGoogleSocialLogin from "../../../DRIVERSIDE/commonComponents/DriverGoogleLogin/DriverGoogleLogin";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import {
  clearForm,
  setSignupFormData,
  setSocialUserData,
} from "../../../store/slices/UserSlice";
import { Autocomplete } from "@react-google-maps/api";
import { CHECK_TYPE } from "../../../utils/Constants";
import SublyApi from "../../../helpers/Api";
import { STATUS_CODES } from "../../../utils/StatusCode";
import Toster from "../../../utils/Toaster";
import { STATUS_MSG } from "../../../utils/StatusMsg";
import { removeExtraPlusSigns } from "../../../utils/Loader/Helper/helper";

//---------function for signUp first step form----------
function SignUpFirstForm({ setNextStep, previousStep }) {
  const { userToken } = useSelector((state) => state.user);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    register,
    handleSubmit,
    setValue,
    control,
    clearErrors,
    watch,
    formState: { errors },
  } = useForm();

  const socialProfileData = location.state;

  const autocomplete = useRef(null);
  const [passwordShow, setPasswordShow] = useState(false);
  const [confirmPassShow, setConfirmPassShow] = useState(false);
  const [phoneNo, setPhoneNo] = useState("");
  const [dialCode, setDialCode] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [isSocial, setIsSocial] = useState(1); // 1 (normal login) 2 (social login)
  const { signupFormData, nextStep, socialUserData } = useSelector(
    (state) => state.user
  );
  const placeApiKey = process.env.REACT_APP_GOOGLE_PLACE_API_KEY;
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const [businessLocation, setBusinessLocation] = useState({
    address: "",
    lat: "",
    lng: "",
  });

  useEffect(() => {
    if (
      socialProfileData &&
      socialProfileData.profileData &&
      socialProfileData.profileData.id
    ) {
      setIsSocial(socialProfileData && socialProfileData.id ? 2 : 1);
      dispatch(
        setSocialUserData({
          id: socialProfileData.profileData.id,
          name: socialProfileData.profileData.name,
          email: socialProfileData.profileData.email,
          socialType: socialProfileData.socialType,
        })
      );
    } else {
      dispatch(setSocialUserData({}));
      setIsSocial(1);
    }
  }, []);

  // match password and confirm password field
  const password = useRef({});
  password.current = watch("password", "");

  // disable space on key press input
  const handleKeyPress = (e, t) => {
    if (e.target.value == " ") setValue(e.target.name, e.target.value.trim());
  };

  // disable space on key press input email field
  const onInputChange = (e) => {
    setValue(e.target.name, e.target.value.replace(/\s/g, "")); // this remove from all string
  };

  // function for apply validation in phoneNumber field
  const validateNumber = () => {
    if (!phoneNo) {
      if (socialUserData && socialUserData.id) {
        return true;
      } else {
        return `${t("CONTACT_REQUIRED")}`;
      }
    } else {
      const minPhoneNumberLength = 5;
      const maxPhoneNumberLength = 15;
      if (phoneNo.length < minPhoneNumberLength) {
        return `${t("PHONE_MINLENGTH")}`;
      }
      if (phoneNo.length > maxPhoneNumberLength) {
        return `${t("PHONE_MAXLENGTH")}`;
      }
      return true;
    }
  };

  // --------function for show prefilled formdata--------
  useEffect(() => {
    if (previousStep == true) {
      setValue(
        "firstName",
        signupFormData.firstName ? signupFormData.firstName : ""
      );
      setValue(
        "lastName",
        signupFormData.lastName ? signupFormData.lastName : ""
      );
      setValue("email", signupFormData.email ? signupFormData.email : "");
      setValue(
        "driverCompany",
        signupFormData.driverCompany ? signupFormData.driverCompany : ""
      );
      setValue(
        "driverLocation",
        signupFormData.driverLocation
          ? signupFormData.driverLocation.address
          : ""
      );
      setBusinessLocation(
        signupFormData.businessLocation ? signupFormData.businessLocation : ""
      );
      setDialCode(signupFormData.dialCode ? signupFormData.dialCode : "");
      setCountryCode(
        signupFormData.countryCode ? signupFormData.countryCode : ""
      );
      setPhoneNo(signupFormData.phoneNo ? signupFormData.phoneNo : "");
      setValue(
        "password",
        signupFormData.password ? signupFormData.password : ""
      );
      setValue(
        "confirmPassword",
        signupFormData.confirmPassword ? signupFormData.confirmPassword : ""
      );
    } else {
      dispatch(clearForm(true));
      clearErrors("");
      setValue("firstName", firstName ? firstName : "");
      setValue("lastName", lastName ? lastName : "");
      setValue("email", socialUserData ? socialUserData.email : "");
      setIsSocial(socialUserData && socialUserData.id ? 2 : 1);
    }
  }, [nextStep, socialUserData]);

  // for separate name field
  const nameArray =
    socialUserData && socialUserData.name && socialUserData.name.split(" ");
  const firstName = nameArray && nameArray[0];
  const lastName = nameArray && nameArray.slice(1).join(" ");

  // --------function for submit formdata--------
  const onSubmit = (formdata) => {
    if (!businessLocation.lat) {
      setShowErrorMsg(true);
      return;
    }
    // Check email existence
    let emailData = new FormData();
    emailData.append("check_type", CHECK_TYPE.EMAIL);
    emailData.append("email", formdata.email);
    const emailResponse = SublyApi.driverEmailCheck(emailData, userToken);
    emailResponse.then((response) => {
      const ResponseCode = STATUS_MSG[response?.data?.code];
      if (response?.code == STATUS_CODES.SUCCESS) {
        verifyPhone(formdata);
      } else if (response.data.code == STATUS_CODES.SERVER_VALIDATION) {
        Toster(response.data.message, "error");
      } else {
        Toster(t(ResponseCode), "error");
      }
    });
  };

  const verifyPhone = (formdata) => {
    let phoneData = new FormData();
    phoneData.append("check_type", CHECK_TYPE.PHONE_NUMBER);
    phoneData.append("phone_number", phoneNo);
    phoneData.append(
      "dial_code",
      dialCode ? removeExtraPlusSigns(dialCode) : ""
    );

    const phoneResponse = SublyApi.driverEmailCheck(phoneData, userToken);
    phoneResponse.then((response) => {
      const ResponseCode = STATUS_MSG[response?.data?.code];
      if (response?.code == STATUS_CODES.SUCCESS) {
        let userData = {
          ...formdata,
          phoneNo: phoneNo,
          dialCode: dialCode,
          countryCode: countryCode,
          businessLocation,
        };
        dispatch(setSignupFormData(userData));
        setNextStep(1);
      } else if (response.data.code == STATUS_CODES.SERVER_VALIDATION) {
        Toster(response.data.message, "error");
      } else {
        Toster(t(ResponseCode), "error");
      }
    });
  };

  const handlePlaceSelected = (place) => {
    setShowErrorMsg(false);
    setBusinessLocation({
      address: autocomplete?.current?.getPlace()?.formatted_address,
      lat: autocomplete?.current?.getPlace()?.geometry?.location?.lat(),
      lng: autocomplete?.current?.getPlace()?.geometry?.location?.lng(),
    });
    setValue("driverLocation", {
      address: autocomplete.current.getPlace().formatted_address,
      lat: autocomplete.current.getPlace().geometry.location.lat(),
      lng: autocomplete.current.getPlace().geometry.location.lng(),
    });
  };

  const handleLocationChange = (value) => {
    setBusinessLocation((prev) => ({
      ...prev,
      lat: "",
      lng: "",
      address: value,
    }));
    setValue("driverLocation", {
      lat: "",
      lng: "",
      address: value,
    });
  };

  return (
    <div className="main">
      <div className="spaceTopManageDriver">
        <div
          style={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: "cover",
            width: "100%",
            padding: "20px 0px",
            backgroundPositionX: "center",
          }}
        >
          <Container>
            <div className={styles.formBlocks}>
              <div className={styles.signPageCls}>
                <span>
                  <strong>1</strong>/4
                </span>
                <h1 className="commonheading">{t("CREATE_ACCOUNT")}</h1>
              </div>
              <Form onSubmit={handleSubmit(onSubmit)} className="formSection">
                <Form.Group className="mb-3">
                  <Form.Label>{t("FIRST_NAME")}</Form.Label>
                  <Form.Control
                    placeholder={`${t("FIRSTNAME_TEXT")}`}
                    type="text"
                    {...register("firstName", {
                      onChange: (e) => handleKeyPress(e),
                      required: {
                        value: true,
                        message: `${t("FIRSTNAME_REQUIRED")}`,
                      },
                      minLength: {
                        value: 2,
                        message: `${t("FIRSTNAME_MINLENGTH")}`,
                      },
                      maxLength: {
                        value: 50,
                        message: `${t("FIRSTNAME_MAXLENGTH")}`,
                      },
                      ...sanitizeHtmlTags(),
                    })}
                  />
                  {errors.firstName && (
                    <span className="errorMsg">{errors.firstName.message}</span>
                  )}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>{t("LAST_NAME")}</Form.Label>
                  <Form.Control
                    placeholder={`${t("LASTNAME_TEXT")}`}
                    type="text"
                    {...register("lastName", {
                      onChange: (e) => handleKeyPress(e),
                      required: {
                        value: true,
                        message: `${t("LASTNAME_REQUIRED")}`,
                      },
                      minLength: {
                        value: 2,
                        message: `${t("LASTNAME_MINLENGTH")}`,
                      },
                      maxLength: {
                        value: 50,
                        message: `${t("LASTNAME_MAXLENGTH")}`,
                      },
                      ...sanitizeHtmlTags(),
                    })}
                  />
                  {errors.lastName && (
                    <span className="errorMsg">{errors.lastName.message}</span>
                  )}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>{t("EMAIL")}</Form.Label>
                  <Form.Control
                    disabled={socialUserData.email}
                    placeholder={`${t("EMAIL_TEXT")}`}
                    type="text"
                    {...register("email", {
                      onChange: (e) => onInputChange(e),
                      pattern: {
                        value:
                          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                        message: `${t("EMAIL_INVALID")}`,
                      },
                      minLength: {
                        value: 5,
                        message: `${t("EMAIL_MINLENGTH")}`,
                      },
                      maxLength: {
                        value: 100,
                        message: `${t("EMAIL_MAXLENGTH")}`,
                      },
                      ...sanitizeHtmlTags(),
                      required: {
                        value: isSocial == 1 ? true : false,
                        message: `${t("EMAIL_REQUIRED")}`,
                      },
                    })}
                  />
                  {errors.email && (
                    <span className="errorMsg">{errors.email.message}</span>
                  )}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>{t("COMPANY_TEXT")}</Form.Label>
                  <Form.Control
                    placeholder={`${t("ENTER_COMPANY")}`}
                    type="text"
                    {...register("driverCompany", {
                      onChange: (e) => handleKeyPress(e),
                      minLength: {
                        value: 2,
                        message: `${t("MIN_2_CHAR_REQUIRED")}`,
                      },
                      maxLength: {
                        value: 100,
                        message: `${t("MAX_100_CHAR_ALLOWED")}`,
                      },
                    })}
                  />
                  {errors.driverCompany && (
                    <span className="errorMsg">
                      {errors.driverCompany.message}
                    </span>
                  )}
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>{t("BUSINESS_LOCATION")}</Form.Label>
                  <Autocomplete
                    placeApiKey={placeApiKey}
                    onLoad={(auto) => {
                      autocomplete.current = auto;
                    }}
                    onPlaceChanged={(place) => handlePlaceSelected(place)}
                  >
                    <Form.Control
                      type="text"
                      placeholder={t("ENTER_BUSINESS_LOCATION")}
                      value={businessLocation.address}
                      onChange={(e) => handleLocationChange(e.target.value)}
                      // {...register("driverLocation", {
                      //   onChange: (e) => handleLocationChange(e.target.value),
                      //   required: `${t("PLS_ENTER_BUSINESS_LOCATION")}`,
                      // })}
                      className="form-control"
                      style={{
                        border:
                          showErrorMsg && !businessLocation.lat
                            ? "1px solid #dc3545"
                            : "",
                      }}
                    />
                  </Autocomplete>
                  {errors.driverLocation && (
                    <span className="errorMsg">
                      {errors.driverLocation.message}
                    </span>
                  )}
                  {showErrorMsg && (
                    <span className="errorMsg">
                      {t("PLS_ENTER_BUSINESS_LOCATION")}
                    </span>
                  )}
                </Form.Group>
                <Form.Group className="mb-3 phoneInputSet">
                  <Form.Label>{t("CONTACT")}</Form.Label>
                  <Controller
                    control={control}
                    name="phoneNumber"
                    render={({ field }) => (
                      <PhoneInput
                        placeholder={`${t("CONTACT_TEXT")}`}
                        country={"gb"}
                        value={dialCode?.toString() + phoneNo?.toString()}
                        countryCodeEditable={false}
                        enableLongNumbers={true}
                        onChange={(value, country) => {
                          let dialCode = country.dialCode;
                          let phone = value.slice(
                            dialCode.length,
                            value.length
                          );
                          setCountryCode(country.countryCode);
                          setDialCode(dialCode);
                          setPhoneNo(phone);
                          field.onChange(value);
                          clearErrors("phoneNumber");
                        }}
                      />
                    )}
                    rules={isSocial == 1 ? { validate: validateNumber } : ""}
                  />
                  {errors.phoneNumber && (
                    <span className="errorMsg">
                      {errors.phoneNumber.message}
                    </span>
                  )}
                </Form.Group>

                {socialUserData && socialUserData.id ? (
                  ""
                ) : (
                  <>
                    <Form.Group className="mb-3 setIconPassword">
                      <Form.Label>{t("PASSWORD")}</Form.Label>
                      <Form.Control
                        placeholder={`${t("PASSWORD_TEXT")}`}
                        type={passwordShow ? "text" : "password"}
                        autoComplete="new-password"
                        {...register("password", {
                          onChange: (e) => {
                            onInputChange(e);
                          },
                          required: {
                            value: true,
                            message: `${t("PASSWORD_REQUIRED")}`,
                          },
                          minLength: {
                            value: 6,
                            message: `${t("PASS_MINLENGTH")}`,
                          },
                          maxLength: {
                            value: 12,
                            message: `${t("PASS_MAXLENGTH")}`,
                          },
                          pattern: {
                            value:
                              /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#$%^&*@~!])/,
                            message: `${t("INVALID_PASSWORD")}`,
                          },
                          ...sanitizeHtmlTags(),
                        })}
                      />
                      {errors.password && (
                        <span className="errorMsg">
                          {errors.password.message}
                        </span>
                      )}

                      <div className="passwordicon">
                        {passwordShow ? (
                          <FaEye
                            className="icon"
                            onClick={() => setPasswordShow(!passwordShow)}
                          />
                        ) : (
                          <FaEyeSlash
                            className="icon"
                            onClick={() => setPasswordShow(!passwordShow)}
                          />
                        )}
                      </div>
                    </Form.Group>
                    <Form.Group className="mb-3 setIconPassword">
                      <>
                        <Form.Label>{t("CONFIRM_PASSWORD")}</Form.Label>
                        <Form.Control
                          placeholder={`${t("CONFIRM_PASS_TEXT")}`}
                          type={confirmPassShow ? "text" : "password"}
                          autoComplete="new-password"
                          {...register("confirmPassword", {
                            onChange: (e) => {
                              onInputChange(e);
                            },
                            ...sanitizeHtmlTags(),
                            required: {
                              value: true,
                              message: `${t("CONFIRM_PASS_REQUIRED")}`,
                            },
                            minLength: {
                              value: 6,
                              message: `${t("CONFIRM_PASS_MINLENGTH")}`,
                            },
                            maxLength: {
                              value: 12,
                              message: `${t("CONFIRM_PASS_MAXLENGTH")}`,
                            },
                            pattern: {
                              value:
                                /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#$%^&*@~!])/,
                              message: `${t("INVALID_PASSWORD")}`,
                            },
                            validate: (value) =>
                              value === password.current || `${t("NOT_MATCH")}`,
                          })}
                        />
                        {errors.confirmPassword && (
                          <span className="errorMsg">
                            {errors.confirmPassword.message}
                          </span>
                        )}
                        <div className="passwordicon">
                          {confirmPassShow ? (
                            <FaEye
                              className="icon"
                              onClick={() =>
                                setConfirmPassShow(!confirmPassShow)
                              }
                            />
                          ) : (
                            <FaEyeSlash
                              className="icon"
                              onClick={() =>
                                setConfirmPassShow(!confirmPassShow)
                              }
                            />
                          )}
                        </div>
                      </>
                    </Form.Group>
                  </>
                )}
                <div className="commonButton">
                  <button type="submit">{t("NEXT")}</button>
                </div>

                <div className="socialbutton">
                  {/* <DriverFacebookSocialLogin /> */}
                  <DriverGoogleSocialLogin />
                </div>

                <p className={styles.bottomText}>
                  {t("ALREADY_ACCOUNT")}{" "}
                  <NavLink to="/driver-login">{t("SIGNIN")}</NavLink>
                </p>
              </Form>
            </div>
          </Container>
        </div>
      </div>
    </div>
  );
}
export default SignUpFirstForm;

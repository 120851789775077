//---------------- Common Text----------------------
export const TRANSLATIONS_EN = {
  //----------------Header Text----------------------
  HOME: "Home",
  CREATE_JOB: "Create job",
  BOOK_A_JOB: "Book a Job",
  LOGIN: "Login",
  DRIVERS_LOGIN: "Driver's login",
  DRIVER: "Become a driver",
  FAQ: "FAQs",
  SUPPORT: "Support",
  JOB: "Jobs",
  MY_BOOKINGS: "My Bookings",
  ENGLISH: "English",
  SPANISH: "Spanish",
  FRENCH: "French",
  TURKISH: "Turkish",
  CHINESE: "Chinese",
  ARABIC: "Arabic",

  //----------------Footer Text----------------------
  COPYRIGHT: "All Rights Reserved",
  SWIFTVAN: "SwiftVan",
  PRIVACY: "Privacy Policy",
  TERMS: "Terms & Conditions",

  //----------------HomePage Text----------------------
  BOOK_MOVE: "Book your move in 60 sec",
  WHAT_MOVING: "Choose your Man and Van Service?",
  BECOME_DRIVER: "Become A DRIVER",
  CALCULATE: "Calculate",
  FREE_INSTANT_QUOTE: "Free Instant Quote",
  SOURSE_LOCATION: "Enter Source Location",
  COLLECT_ADDRESS: "Collection address",
  COLLECTION_ADDRESS: "Collection Address or Postcode",
  DELIVER_ADDRESS: "Delivery address",
  DELIVERY_ADDRESS: "Delivery Address or Postcode",
  DESTINY_LOCATION: "Enter Destination Location",
  ABOUT_US: "About Us",
  CATEGORIES: "What we love",
  DOING: "doing",
  WORKS: "works",
  HOW: "How it",
  FURNITURE_OTHER: "Furniture and other items",
  HOME_REMOVAL: "Home Removals",
  BTOB: "Business to Business",
  SCHEDULE_PICKUP: "Book The Service You Require",
  ARRIVE: "A Driver Will Arrive At Collection Address",
  DELIVER: "Items Are Delivered Safely And On Time",

  //----------------LoginForm Text----------------------
  CUSTOMER_LOGIN: "Customer Login",
  EMAIL: "Email",
  EMAIL_TEXT: "Enter email",
  COMPANY_TEXT: "Company name",
  ENTER_FULL_NAME: "Enter full name",
  ENTER_COMPANY: "Enter company name (optional)",
  PASSWORD: "Password",
  PASSWORD_TEXT: "Enter password",
  LOGIN: "LOGIN",
  FORGOT_PASSWORD: "Forgot password?",
  EMAIL_REQUIRED: "Please enter your email address.",
  EMAIL_INVALID: "Please enter a valid email address.",
  PASSWORD_REQUIRED: "Please enter your password.",
  PASS_MINLENGTH: "Please enter password, with a minimum of 6 characters.",
  PASS_MAXLENGTH: "Please enter password, with a maximum of 12 characters.",
  NO_ACCOUNT: "Don’t have an account?",
  SINGUP: "Signup",
  EMAIL_MINLENGTH: "Please enter email, with a minimum of 5 characters.",
  EMAIL_MAXLENGTH: "Please enter email, with a maximum of 100 characters.",

  //----------------ForgotPassword Text----------------------
  CANCEL: "Cancel",
  RESET: "Reset",
  FORGOT_TEXT:
    "Enter your E-mail address and we ‘ll send you a link to reset your password",
  FORGOT_PASSWORD_TEXT: "FORGOT PASSWORD",

  //----------------SignUp Form Text----------------------
  CREATE_ACCOUNT: "Create account",
  FIRST_NAME: "First name",
  LAST_NAME: "Last name",
  CONTACT: "Contact number",
  CONFIRM_PASSWORD: "Confirm password",
  FIRSTNAME_TEXT: "Enter first name",
  FIRSTNAME_REQUIRED: "Please enter your first name.",
  FIRSTNAME_MINLENGTH: "First name should contain 2 characters or more.",
  FIRSTNAME_MAXLENGTH:
    "Please enter first name, with a maximum of 50 characters.",
  LASTNAME_TEXT: "Enter last name",
  LASTNAME_REQUIRED: "Please enter your last name.",
  LASTNAME_MINLENGTH: "Last name should contain 2 characters or more.",
  LASTNAME_MAXLENGTH:
    "Please enter last name, with a maximum of 50 characters.",
  CONTACT_TEXT: "Select contact number",
  CONTACT_REQUIRED: "Please enter your contact number.",
  CONFIRM_PASS_TEXT: "Enter confirm password",
  CONFIRM_PASS_REQUIRED: "Please enter your confirm password.",
  CONFIRM_PASS_MINLENGTH:
    "Please enter confirm password, with a minimum of 6 characters.",
  CONFIRM_PASS_MAXLENGTH:
    "Please enter confirm password, with a maximum of 12 characters.",
  NOT_MATCH: "Password does not match the confirm password.",
  ALREADY_ACCOUNT: "Already have an account?",
  SIGNIN: "Sign In",
  SIGNUP_TEXT: "Sign Up",
  INVALID_PASSWORD:
    "Password should have 1 upper case, 1 lower case, 1 number, 1 special character min 6 char.",
  BUSINESS_LOCATION: "Business location",
  ENTER_BUSINESS_LOCATION: "Enter business location",
  PLS_ENTER_BUSINESS_LOCATION: "Please select business location",

  //----------------Reset Password Text----------------------
  NEW_PASSWORD: "New Password",
  SUBMIT: "Submit",
  OTP_REQUIRED: "OTP is required",
  DIGIT_REQUIRED: "Please enter a 4-digit OTP",
  NUMBER_REQUIRED: "Please enter only numbers.",

  //----------------SUCCESS Messages Text----------------------
  LOGIN_SUCCESSFULLY: "Logged in successfully.",
  CREATE_ACCOUNT_SUCCESSFUL: "Create account successfully.",
  RESET_SUCCESFULLY: "Reset code send successfully!",
  UPDATE_SUCCESFULLY: "Reset password successfully.",

  //----------------Error Messages Text----------------------
  HEADER_IS_MISSING: "Header is missing.",
  DEVICE_TYPE_NOT_ALLOWED: "Device type not allowed.",
  INVALID_API_KEY: "Invalid Api Key.",
  TOKEN_REQUIRE_AUTHENTICATION: "A token is required for authentication.",
  INVALID_TOKEN: "Invalid Token.",
  SERVER_VALIDATION: "Validation error.",
  USER_NOT_FOUND: "User not found",
  ACCOUNT_BLOCKED: "Your account is currently blocked.",
  SOCIAL_USER_NOT_FOUND: "Social user not found",
  FAILED_TO_ACCESS: "Session has expired.",
  INTERNAL_SERVER_ERROR: "Internal server error",
  INVALID_LOGIN_CREDENTIAL: "Invalid login credentials.",
  ACCOUNT_NOT_FOUND: "Account not found, please enter the correct email.",
  ACCOUNT_INACTIVE: "Your account is currently inactive.",
  ACCOUNT_DELETED: "Your account has been deleted.",
  OLD_PASSWORD_DOES_NOT_MATCH: "Old password not match.",
  EMAIL_ALREADY_IN_USE: "Email already in use, please use other emails.",
  PHONE_NUMBER_EXIST:
    "Phone number already in use, please use other phone numbers.",
  SOCIALID_EXIST: "Social id alredy exists",
  CODE_EXIST: "Code already used",
  CODE_NOT_MATCH: "Code does not matched",
  EMAIL_LINKED_SOCIALUSER: "Email is linked with social user",
  EMAIL_LINKED_NORMALUSER: "Email is linked with normal user",
  EMAIL_FAIL: "Email send fail",
  VEHICLE_IMG: "Vehicle image is required",
  LICENSE_IMG: "Driving lincence is required",
  GT_INSURANCE_IMG: "Goods in transit insurance is required",
  HR_INSURANCE_IMG: "Hire & reward insurance is required",
  PL_INSURANCE_IMG: "Public liability insurance is required",
  VEHICLE_IMG_SIZE: "Vehicle image max size 10 mb",
  INVALID_VEHICLE_IMG: `Invalid vehicle image extention pls use ".jpg", ".jpeg", ".png", ".webp"`,
  INVALID_VEHICLE_ID: "invalid vehicle id",
  INVALID_COLOR_ID: "invalid vehicle color id",
  LICENSE_IMG_SIZE: "Driving lincence max size 10 mb",
  INVALID_LICENSE_IMG: `Driving lincence extention pls use ".jpg", ".jpeg", ".png", ".webp" , ".pdf"`,
  GT_INSURANCE_IMG_SIZE: "Goods in transit insurance max size 10 mb",
  INVALID_GT_INSURANCE_IMG: `Goods in transit insurance  extention pls use ".jpg", ".jpeg", ".png", ".webp", ".pdf"`,
  HR_INSURANCE_IMG_SIZE: "Hire & reward insurance max size 10 mb",
  INVALID_HR_INSURANCE_IMG: `Hire & reward insurance  extention pls use ".jpg", ".jpeg", ".png", ".webp", ".pdf"`,
  PL_INSURANCE_IMG_SIZE: "Public liability insurance max size 10 mb",
  INVALID_PL_INSURANCE_IMG: `Public liability insurance  extention pls use ".jpg", ".jpeg", ".png", ".webp" , ".pdf"`,
  FAILED_IMG: "Failed to upload image",
  FAILED_LICENSE_IMG: "Failed to upload  driving licence",
  FAILED_GT_IMG: "Failed to upload goods in transit insurance",
  FAILED_HR_IMG: "Failed to upload hire reward insurance",
  FAILED_PL_IMG: "Failed to upload public liability insurance",
  INVALID_CATEGORY_ID: "Invalid / inactive category id",
  INVALID_VEHICLETYPE_ID: "Invalid / inactive vehicle type id",
  INVALID_DRIVER_ID: "Invalid / inactive driver id",
  INVALID_SOURSE_FLOOR_ID: "Invalid / inactive source floor id",
  INVALID_DESTINATION_FLOOR_ID: "Invalid / inactive destinaion floor id",
  INVALID_HELPER_ID: "Invalid / inactive helper id",
  INVALID_CATEGORY_JSON: "Invalid / inactive category json",
  ACCESS_DENIED:
    "Access Denied NOTE: this code will come when a staff is trying to run the apis which are not allow to him",
  WRONG: "something went wrong",
  INVALID_UNITID: "Invalid / inactive unit id",
  INVALID_STOP: "invalid  add stop json",
  INVALID_SPROPERTYID: "Invalid / inactive source property type id",
  INVALID_DPROPERTYID: "nvalid / inactive destination property type id",
  INVALID_SHEDULE_DATA: "Invalid json format of schedule data",
  TIME_EXIST:
    "This time is already exits for same day please use another start time",
  CUSTOMER_NOT_PERMIT: "Customer does not have permission for this action",
  INVALID_PRICE: "Invalid json format of price setting data",
  FOR_SCHEDULE: "For schedule data only two slot allowed same day",
  DRIVER_NOT_PERMISION: "Driver does not have permission for this action",
  CAN_NOT_DELETE: "You can not delete this account.",
  ALEREADY_LICENCE_USE:
    "Licence number already used, please use other licence numbers.",
  PROFILE_REQUIRED: "Profile image is required",
  PROFILE_IMG_SIZE: "Profile image max size 10 mb",
  INVALID_PROFILE: `Invalid profile image extention pls use ".jpg", ".jpeg", ".png", ".webp"`,
  DOC_IMG_REQUIRED: "Document image is required",
  DOC_IMG_SIZE: "Document image max size 10 mb",
  INVALID_DOC_IMG: `Invalid document image extention pls use ".jpg", ".jpeg", ".png", ".webp"`,
  INVALID_DOC: "Invalid document",
  PASS_NOT_MATCH: "New password and confirm password not matched",
  NOTIFY_ALREADY_EXIST: "This notification status already exists",
  ADD_VEHICLE: "Please add vehicle",
  VEHICLE_NAME_ALREDY: "Vehicle name already exists, please use another name",
  NOT_PERMIT_USER: "User does not have permission for this action",
  VEHICLE_TYPE_NOT_DELETE: "This vehicle type can not deleted",
  VEHICLE_TYPE_STATUS: "Vehicle type status already exists",
  ESTIMATE_WEIGHT_ID: "Invalid estimated weight id",
  INVALID_JOB_TEMP_ID: "Invalid job temp id",
  INVALID_KEY: "Invalid key name",
  MASTER_DATA_EXIST: "Master data name already exists",
  INVALID_MASTER_DATA: "Invalid master data id",
  MASTER_DATA_NOT_DELETE: "Can not deleted this master data",
  MASTER_DATA_STATUS: "Master data status already exists",
  CATEGORY_ICON: "Category icon is required",
  CATEGORY_ICON_SIZE: "Category icon max size 10 mb",
  INVALID_CATEGORY_ICON: `Invalid category icon extention pls use ".jpg", ".jpeg", ".png", ".webp"`,
  CATEGORY_ADD: "Category added successfully",
  CATEGORY_UPDATE: "Category updated successfully",
  CUSTOMER_EXIST: "Users already assosiated with customer",
  DRIVER_EXIST: "Users already assosiated with driver",

  //----------------Session Expire Text----------------------
  SESSION_EXPIRED: "Your Session Expired!",

  //----------------Profile Page Text----------------------
  PROFILE: "My Profile",
  MY_ACCOUNT: "My Account",
  SETTINGS: "Settings",
  EDIT_PROFILE: "Edit Profile",
  USER_INFO: "User Info",
  UPDATE: "Update",
  LOGOUT: "Logout",
  DELETE_ACCOUNT: "Delete Account",
  SAVE_CARD: "Saved Cards",
  CHANGE_PASSWORD: "Change Password",
  JOB_HISTORY: "Job History",
  UNIQUE_ID: "Driver Id",

  //----------------Driver auth Page Text----------------------
  BACK: "Back",
  NEXT: "Next",
  DONE: "Done",
  SELECT_VEHICLE_COLOR: "Select Vehicle Color",
  SELECT_VAN: "Select Van",
  VEHICLE_INFO: "Vehicle Info",
  LICENSE_PLATE_NUMBER: "Licence plate number",
  ENTER_LICENSE: "Enter licence plate number",
  LICENSE_REQUIRED: "Please enter Licence plate number.",
  LICENSE_MINLENGTH:
    "Please enter Licence plate number, with a minimum of 2 characters.",
  LICENSE_MAXLENGTH:
    "Please enter Licence plate number, with a maximum of 50 characters.",
  LICENSE_PATTERN_MATCH:
    "Number should contain at least one alphabet and digit.",
  VAN_TYPE: "Van type",
  SELECT_VAN: "Select van type",
  NEW_DRIVER_SELECT_VAN: "New driver van selection",
  VAN_REQUIRED: "Please select van.",
  COLOR_VEHICLE: "Color of the vehicle",
  SELECT_COLOR: "Select vehicle color",
  COLOR_REQUIRED: "Please select vehicle color.",
  VEHICLE_YEAR: "Year of the vehicle",
  YEAR_OF_VEHICLE: "Select year of the vehicle",
  VEHICLE_YEAR_REQUIRED: "Please select vehicle year",
  YEAR_ALLOW_DIGITS: "Please enter a 4-digit year",
  VEHICLE_PHOTO: "Vehicle Photo",
  VEHICLE_PHOTO_REQUIRED: "Vehicle photo is required",
  UPLOAD_VEHICLE: "Upload Vehicle Photo",
  UPLOAD: "Upload",
  DESCRIPTION: "Description",
  ENTER_DISCRIPTION: "Enter description",
  DESCIPTION_REQUIRED: "Please enter vehicle description.",
  DESCRIPTION_MINLENGTH:
    "Please enter vehicle description, with a minimum of 2 characters.",
  DESCRIPTION_MAXLENGTH:
    "Please enter vehicle description, with a maximum of 300 characters.",
  UPLOAD_DOCUMENT: "UPLOAD DOCUMENTS",
  DRIVING_LICENSE: "Driving Licence",
  DRIVING_LICENSE_REQUIRED: "Driving Licence is required",
  GT_INSURANCE_REQUIRED: "Goods In Transit Insurance is required",
  HR_INSURANCE_REQUIRED: "Hire & Reward Insurance is required",
  PL_INSURANCE_REQUIRED: "Public Liability Insurance is required",
  GT_TITLE: "Goods In Transit Insurance",
  HR_TITLE: "Hire & Reward Insurance",
  PL_TITLE: "Public Liability Insurance",
  UPLOAD_LICENSE: "Upload Driving License",
  UPLOAD_TRAN_INSURANCE: "Upload Transit Insurance",
  UPLOAD_REWARD_INSURANCE: "Upload Hire & Reward Insurance",
  UPLOAD_LIABILITY_INSURANCE: "Upload Public Liability Insurance",
  UPLOAD_REGISTRATION: "Upload Vehicle Registration",
  ALERT: "Alert",
  WARNING: "Warning",
  REMOVE_ALERT: "Are you sure you want to remove",
  YES: "Yes",
  DRIVER_LOGIN: "Driver Login",
  HAVE_JOBS_REQUEST: "Your Have Jobs Request",
  OFFFER_FOR_YOU: "FIND JOBS FOR YOU",
  FILTER: "Filter",

  //-----------------------Create job localization text--------------------
  SELECT: "Select",
  SELECTED: "Selected",
  ENTER: "Enter",
  FURNITURE_AND_OTHER: "Furniture & Other Items",
  SOURCE_LOCATION: "Source Location",
  DESTINATION_LOCATION: "Destination Location",
  SELECT_FLOOR: "Select Floor",
  LIFT_AVAILABLE: "Lift Available",
  STOPS: "Stops",
  STOP: "Stop",
  VIA: "Via",
  FLOOR: "Floor",
  ADD_STOPS: "Add Stop",
  ADD_VIA: "Add Via",
  ADD_MORE: "Add More",
  ADD_MORE_STOPS: "Add more stops",
  ADD_MORE_VIA: "Add more via",
  SELECT_PROPERTY: "Select Property Type",
  SELECT_YOUR_ITEMS: "Please select your items",
  AVAILABILITY: "Availability",
  ESTIMATED_TIME_ARRIVAL: "Estimated time of arrival",
  WANT_PACKING:
    "Would you like to add packing service? (all materials will be provided by the team)",
  SELECT_PACKAGES_INFO: "Custom Size Items",
  ESTIMATED_DIMENSIONS: "Estimated dimensions",
  VAN_GUIDE_TEXT: "Van Guide",
  VAN_GUIDE: "Van Size Guide",
  VIEW_VAN_GUIDE: "View van size guide",
  JOB_SUMMARY: "Job Summary",
  NOT_AVAILABLE: "Not Available",
  ESTIMATED_TIME_JOB: "Estimated time of job",
  ADDRESS: "Address",
  TIME_OF_JOB: "Time of job",
  COUPON_CODE: "Coupon Code",
  PROMO: "Promo",
  SELECT_PROMO_CODE: "Select promo",
  SEARCH_PROMO: "Search promo",
  EDIT_JOB: "Edit Job",
  GET_INSTANT_PRICES: "Get Instant Prices",
  HIRE_YOUR_DRIVER: "SELECT YOUR DRIVER",
  SELECT_BOOKING_DRIVER:
    "Select the driver you would like to service your booking.",
  JOBS_BOOKED: "Jobs completed",
  VIEW_MORE: "View More",
  REVIEW: "Review",
  REVIEWS: "Reviews",
  ACCEPT: "ACCEPT",
  DRIVER_DETAILS: "DRIVER DETAILS",
  RATINGS: "Ratings",
  HELPER_AVAILABILITY: "Helper & Availability",
  PAYMENT: "PAYMENT",
  PAYMENT_TEXT: "Payment",
  PAYMENT_DETAILS: "Payment Details",
  AMOUNT: "Amount",
  PROCESSING_FEE: "Processing Fee",
  DISCOUNT: "Discount",
  TOTAL_AMOUNT: "Total Amount",
  ADD_CARD: "Add Card",
  PAY: "Pay",
  CARD_NUMBER: "Card number",
  EXPIRY: "Expiry",
  OK: "Ok",
  NO: "No",
  SUCCESS: "Success",
  APPLY: "Apply",
  VALID_DATE: "Valid Date",
  BOOKING_CONFIRMED: "Booking Confirmed",
  ENQUIRY_SUBMITTED: "Enquiry has submitted successfully.",
  JOB_DETAILS: "Job Details",
  TRACKING: "Tracking",
  TRACK_ORDER: "Track order",
  JOBS_NOT_FOUND: "Jobs not found, please try again!",
  COMPLETED: "Completed",
  DELIVERY_IMAGE: "Delivery Image",
  PICKUP_IMAGE: "Pickup Image",
  GIVE_REVIEW: "Give Review",
  REVIEW_SUBMITTED: "Review submitted successfully.",
  LOAD_MORE: "Load more",
  REJECT_THIS_JOB: "reject this job",
  ACCEPT_THIS_JOB: "accept this job",
  OWN_ITEM: "Own Item",
  QUANTITY: "Quantity",
  WIDTH: "Width",
  HEIGHT: "Height",
  DEPTH: "Depth",
  WEIGHT: "Weight",
  ADMIN_COMMISSION: "Admin Commission ",
  ACCEPT_TEXT: "Accept",
  REJECT: "Reject",
  STATUS_PENDING: "Status Pending Driver Side",
  STATUS_ACCEPTED: "Driver has accepted the job",
  STATUS_REJECTED: "Driver has rejected the job",
  ADMIN_GIVEN_PRICE: "Admin Given Price",
  PENDING_FROM_ADMIN: "Pending From Admin",
  PYMT_PENDING_FROM_ADMIN: "Payment Pending From Admin",
  TOTAL_EARNING: "Total Earning",
  STATUS_CHANGE_SUCCESS: "Status changed successfully.",
  WELCOME_TO_CITY2CITY: "Welcome to City2city",
  HOME_TAB_TEXT:
    "Say goodbye to the stress of moving and hello to a seamless efficient moving experience.",
  PLEASE_SELECT_SERVICE_REQUIRE: "Please select which service you require.",

  //-----------------------Create job validations text--------------------
  ENTER_ADDRESS: "Enter address",
  ENTER_SOURCE_LOCATION: "Enter source location",
  ENTER_DESTINATION_LOCATION: "Enter destination location",
  ENTER_STOP: "Enter stop",
  ENTER_VIA: "Enter via",
  ENTER_ALL_STOPS_ADDRESS: "Please enter address for all stops.",
  MAXIMUM_STOPS_ALLOWED: "Maximun 9 stops are allowed.",
  MIN_2_CHAR_REQUIRED: "Minimum 2 characters required.",
  MIN_3_CHAR_REQUIRED: "Minimum 3 characters required.",
  SELECT_VEHICLE_TYPE: "Please select your van size",
  MAKE_SURE_VAN_SIZE:
    "It is important that you select the correct vehicle for the amount that you wish to move. Please refer to our van guide if you need help finding a suitable vehicle for you.",
  SELECT_PEOPLE_HELP:
    "Please select how many people you would like to help load and unload",
  SELECT_DATE_TIME_JOB: "Select time and date of job",
  SELECT_HELPER: "Select helper",
  HELPER: "Helper",
  SELECT_DATE: "Select date",
  SELECT_TIME: "Select time",
  SELECT_DATE_TIME: "Select availability date & time",
  SELECT_LOCATION: "Please select a location.",
  SELECT_PRO_TYPE: "Please select property type.",
  PLEASE_SELECT_FLOOR: "Please select a floor.",
  SELECT_ALL_STOPS: "Please select stop.",
  ADD_SOME_ITEMS: "Please add some items to procced.",
  ENTER_ITEMS: "Enter your items here",
  NOT_FOUND_RESULTS: "Does not found any results.!",
  ADD_OWN_ITEM: "Add New Item",
  ITEM_NOT_SAME: "Items name can't be same.",
  ENTER_PACKAGE_INFO: "Please enter the package info.",
  TIME_AHEAD_HOUR: "Time must be 1 hour ahead from now.",
  DRIVERS_NOT_FOUND: "Drivers not found, please try again later!",
  PLS_ADD_CARD: "Please add a card to proceed.",
  PLS_SELECT_CARD: "Please select a card to proceed.",
  CARD_ADDED: "Card added successfully.",
  CARD_REMOVED: "Card removed successfully.",
  SURE_CARD_REMOVED: "Are you sure to remove a card?",
  SUCCESSFULLY_TRANSFERED:
    "Thank you for booking with City2City. Your booking has been confirmed.",
  CONTINUE_TO_PROCEED: "Please continue to proceed.",
  MAX_25_CHAR_ALLOWED: "Must be less than 25 characters.",
  MAX_50_CHAR_ALLOWED: "Must be less than 50 characters.",
  MAX_100_CHAR_ALLOWED: "Must be less than 100 characters.",
  MAX_500_CHAR_ALLOWED: "Must be less than 500 characters.",
  MAX_1000_CHAR_ALLOWED: "Must be less than 1000 characters.",
  PLEASE_SELECT_RATING: "Please select rating.",
  PLEASE_CHOOSE_OPTION: "Please choose a option.",
  NO_NOTIFICATION: "No Notifications",
  SURE_CANCEL_JOB: "Are you sure you want to cancel this job?",
  CANCEL_JOB_TEXT: "Cancel Job",
  CANCELLED: "Cancelled",
  CANCELLED_JOB_SUCCESS: "Job has been cancelled successfully.",
  THANKS_4_REVIEW: "Thanks for your review",
  THANKS_RATING_TEXT:
    "Your rating is success is a long established fact that a reader will be",
  CARDS_NOT_ADD: "Cards not found, please add a card.",
  FAQ_NOT_FOUND: "FAQs are not available now.",
  CLOCK_24_FORMATE: "Clock to be 24 hours format.",
  CANCEL_JOB_CUSTOMER: "Job cancelled by customer",
  CANCEL_JOB_ADMIN: "Job cancelled by admin",
  CANCEL_BY_ADMIN: "Cancelled by admin",
  TOTAL_MILEAGE: "Total Mileage",

  //-----------------------Driver setting module text--------------------
  SCHEDULE: "Schedule",
  PRICE: "Prices",
  SERVICES: "Services",
  PACKING: "Packing",
  PACKING_HELPER_SETTINGS: "Packing & Helper Settings",
  PACKING_HELPER_TEXT: "Packing & Helper",
  RADIUS: "Radius",
  CHANGE: "Change",
  RATING: " Rating",
  YEAR: "Year",
  DRIVER_INFO: "Driver Info",
  CUSTOMER_INFO: "Customer Info",
  DOCUMENT: "Document",
  ADD_VAN: "Add Van",
  PHONE_MAXLENGTH: "Phone number should have maximum 15 digits.",
  PHONE_MINLENGTH: "Phone number should have minimum 5 digits.",
  VEHICLE_CATEGORY: "Category of the vehicle",
  SELECT_VEHICLE: "Select category",
  ADD_VAN_SUCCESS: "Add van successfully.",
  UPDATE_VAN: "Update van successfully.",
  UPDATE_VAN_TEXT: "Update Van",
  INVALID_JOBID: "Invalid job id",
  REQUEST_STATUS: "Request status already exists",
  NOT_CHANGE_STATUS: "Can not change request status",
  START_TIME: "Start time is not greater than end time",
  PAYMENT_INTENT: "This payment intent id already used",
  AVALABLE_DATE: "Avaibility date can not less than current date",
  INVALID_COUPON_ID: "Invalid coupon id",
  COUPON_ALREADY_EXISTS: "Coupon already exists",
  TIME_IS_REQUIRED_FOR_CATEGORY_ITEMS: "Time is required for category items",
  TIME_IS_REQUIRED_FOR_UPDATED_FLOOR: "Time is required for updated floor",
  DRIVER_DOCUMENT_ALREDAY_REJECTED: "Driver document already rejected",
  DRIVER_DOCUMENT_ALREDAY_APPROVED: "Driver document already approved",
  DRIVER_DOCUMENT_ID_INVALID: "Driver document id invalid",
  USER_STATUS: "User status updated",
  IMAGE_REQUIRED: "Image is required for this action",
  ALREADY_RATING: "Already given rating to driver for this job",
  PAYMENT_SUCCESS: "Payment intent generated successfully",
  PAYMENT_CREATE: "Payment intent cant be created",
  PAYMENT_INCOMPLETE: "Payment is incomplete",
  JOB_INCOMPLETE: "Job is incomplete cant request for payment release",
  INVALID_EBQUIRY: "Invalid b2b enquiry id",
  CANCEL_JOB: "Cant cancel this job",
  REFUND_PAYMENT: "Refund payment is incomplete",
  COUPON_ADD_SUCCESS: "Coupon added successfully.",
  COUPON_UPDATE_SUCCESS: "Coupon updated successfully.",
  COUPON_STATUS_CHANGED_SUCCESS: "Coupon status changed successfully.",
  CURRENT_PASS: "Current Password",
  PASSWORD_REQUIRED_CURRENT: "Please enter your current password.",
  PASS_MINLENGTH_CURRENT:
    "Please enter current password, with a minimum of 6 characters.",
  PASS_MAXLENGTH_CURRENT:
    "Please enter current password, with a maximum of 12 characters.",
  PASSWORD_REQUIRED_NEW: "Please enter your new password.",
  PASS_MINLENGTH_NEW:
    "Please enter new password, with a minimum of 6 characters.",
  PASS_MAXLENGTH_NEW:
    "Please enter new password, with a maximum of 12 characters.",
  NOT_MATCH_NEWPASS: "New password does not match the confirm password.",
  UPDATE_PROFILE: "Profile update successfully.",
  PASS_CHANGE_SUCCESS: "Password change successfully.",
  UPDATE_PRICE: "Update Prices",
  VEHICLE_TYPE: "Vehicle Type",
  VEHICLE_INFO: "Vehicle Info",
  VEHICLE_SELECT: "Select Vehicle",
  ENTER_HERE: "Enter here",
  MILES_BEFORE: "Miles before running mileage added",
  MILES_BEFORE_TEXT:
    "How many miles will you travel from your base to the collection before adding running miles?",
  ARRIVAL_MILLEAGE: "Arrival /running mileage (per mile)",
  ARRIVAL_MILLEAGE_TEXT:
    "The amount per mile, after your free running miles, that should be charged when driving from your base to the collection point. Minimum 0.80p",
  MILLEAGE: "Mileage (per mile)",
  MILLEAGE_TEXT: "National average from £1 - £2.50. Minimum price 0.80p",
  OUT_OF_CHARGE: "Out of hours charge 07:00 AM to after 19:00 PM",
  OUT_OF_CHARGE_TEXT: "National average: £10.00 - £20.00",

  STOPAGE: "Stoppage",
  STOPAGE_TEXT:
    "Your fee for stopping at Via addresses on route to destination",
  NO_HELP: "No help required (labour/per hour)",
  NO_HELP_TEXT: "Minimum: £20.00",
  DRIVER_ASSISTANCE: "Driver assistance (labour/per hour)",
  DRIVER_ASSISTANCE_TEXT: "Minimum: £25.00",
  DRIVER_HELPER: "Driver + helper (labour/per hour)",
  DRIVER_HELPER_TEXT: "Minimum: £30.00",
  DRIVER_TWO_HELPER: "Driver + 2 helper (labour/per hour)",
  DRIVER_TWO_HELPER_TEXT: "Minimum: £40.00",
  STAIR_CHARGE: "Stair charge (per flight of stairs/per person)",
  STAIR_CHARGE_TEXT: "£5 is the national average. Minimum Price: £1.00",
  PACKING_PRICE: "Packing Price",
  // PACKING_PRICE_TEXT: "Packing Price",
  UPDATE_PRICE_SUCCESS: "Vehicle price updated successfully.",
  UPDATE_RADIUS: "Update Radius",
  SET_RADIUS: "Radius updated successfully.",
  INVALID_COUPON_CODE: "Invalid coupon code",
  INVALID_RATING_ID: "Invalid rating id",
  CUATOM_ITEM_NAME_ALREADY_EXIST: "Custom item name already exist",
  INVALID_COMMISSION_id_type: "Invalid commission id/type",
  INVALID_ID: "Invalid id",
  VAN_DELETE: "Van deleted successfully.",
  DELETE_ACCOUNT_SUCCESS: "Account deleted successfully.",
  DELETE_ALERT: "Are you sure you want to",
  LOGOUT_SUCCESS: "Logout successfully.",
  LOGOUTTITLE: "logout Account",
  DELETYETITLE: "delete Account",
  TUTORIAL: "Tutorial",
  ALLTYPE: " All type",
  SERVICE_UPDATE: "Update services successfully.",
  CATEGORY_REQUIRED: "Please Select category",
  ADMIN_APPROVED: "Approved by Admin",
  ADMIN_REJECT: "Not Approved by Admin",
  PAYMENT_ALREADT_RELEASED: "Payment already released",
  INVALID_USER_SERVICE_ID: "Invalid user service id",
  DRIVER_SERVICE_ALREADY_REJECRED: "Driver service already rejected",
  DRIVER_SERVICE_ALREADY_APPROVED: "Driver service already approved",
  INVALID_DRIVER_VEHICLE_ID: "Invalid driver vehicle id",
  DRIVER_VEHICLE_ALREADY_REJECTED: "Driver vehicle already rejected",
  DRIVER_VEHICLE_ALREADY_APPROVED: "Driver vehicle already approved",
  COUPON_CODE_ALREADY_USED: "Coupon code already used",
  ADMIN_REJECTED: "Rejected by Admin",
  EXPIRATION_DATE: "Expiration Date",
  EXPIRE_DATE: "expire date",
  REQUIRED: "required.",
  UPDATE_DOCUMENT: "Update document succesfully.",
  STATUS: "status",
  PENDING: "Pending",
  APPROVED: "Approved",
  REJECTED: "Rejected",
  AVAILABILTITY_TEXT_C: "AVAILABILTITY",
  YOUR_AVAILABILTITY: "PLEASE SET YOUR",
  ADD_MORES: "Add more",
  SCHEDULE_UPDATE: "Availability updated successfully.",
  TO: "To",
  ENTER_SCHEDULE_TIME: "Please select time.",
  SET_YOUR: "SET YOUR",
  PACKING_SERVICE_SUB_TEXT:
    "Please select the service you are available to provide.",
  SERVICE_AREA_SUB_TEXT:
    "How far away from your base would you like to travel for Jobs?",

  //-----------------------CUSTOMER SUPPORT--------------------
  TITLE: "Title",
  TITLE_ENTER: "Enter title",
  TITLE_REQUIRED: "Please enter your title.",
  TITLE_MINLENGTH: "Please enter title, with a minimum of 2 characters.",
  TITLE_MAXLENGTH: "Please enter title, with a maximum of 50 characters.",
  ENTER_DESCRIPTION: "Enter description",
  DESCRIPTION_REQUIRED: "Please enter your description.",
  DESCRIPTION_MINLENGTH:
    "Please enter description, with a minimum of 2 characters.",
  DESCRIPTION_MAXLENGTH:
    "Please enter description, with a maximum of 200 characters.",
  SUPPORT_UPDATE_SUCCESS: "Support Update successfully.",

  //-----------------------DRIVER SUPPORT--------------------
  CATEGORIE: "Category",
  SELECT_BOOKING_TYPE: "Select booking type",
  BOOKING_TYPE: "Booking type",
  STATUS_TYPE: "Status type",
  ISSUE_TYPE: "Issue type",
  SELECT_STATUS_TYPE: "Select status type",
  SELECT_ISSUE_TYPE: "Select issue type",
  RAISE_A_TICKET: "Raise a ticket",
  SUPPORT_SUBMIT_SUCCESS: "Support submitted successfully.",

  //-----------------------DRIVER TUTORIAL--------------------
  CONTINUE: "Continue",
  FURNITURE_OTHER_ITEMS: "Furniture 7 Other Items",

  //-----------------Bank Info--------------------------------
  BANK_ACCOUNT: "Bank Account",
  ADD_BANK_ACCOUNT: "Add Bank Account",
  BANK_NAME: "Bank Name",
  ENTER_BANK_NAME: "Enter bank name",
  BANK_NAME_REQUIRED: "Please enter bank name.",
  BANK_NAME_MINLENGTH:
    "Please enter bank name, with a minimum of 3 characters.",
  BANK_NAME_MAXLENGTH:
    "Please enter bank name, with a maximum of 100 characters.",

  BANK_ACCOUNT_NUMBER: "Bank Account Number",
  ENTER_BANK_ACCOUNT_NUMBER: "Enter bank account number",
  BANK_ACCOUNT_NUMBER_REQUIRED: "Please enter bank account number.",
  BANK_ACCOUNT_NUMBER_MINLENGTH:
    "Please enter bank account number, with a minimum of 2 digits.",
  BANK_ACCOUNT_NUMBER_MAXLENGTH:
    "Please enter bank account number, with a maximum of 25 digits.",
  BANK_SORT_CODE: "Bank Sort Code",
  ENTER_BANK_SORT_CODE: "Enter bank sort code",
  BANK_SORT_CODE_REQUIRED: "Please enter bank sort code.",
  BANK_SORT_CODE_MINLENGTH:
    "Please enter bank sort code, with a minimum of 2 digits.",
  BANK_SORT_CODE_MAXLENGTH:
    "Please enter bank sort code, with a maximum of 25 digits.",
  BANK_INFO_SUCCESS: "Bank information update successfully.",
  JOB_VAN_GUIDE: `Struggling to decide what size of moving van you need? 
Our support team are happy to advise you on the best size vehicle for your move but the following will give you an idea of the typical vehicle size requirements and uses.`,
  VAN_TITLE: `Typical Luton Van (Giant Van) Load Space Specifications - Book today`,
  VAN_DESCRIPTION: `The Luton Van is the champion of 3 bedroom flat/house removals, office relocations and heavy item moves. The Luton Van provides superb flexibility for bulky items such as furniture and crates. Unlike panel vans, the entire load space is above the rear wheels, so the internal wheel arches don't interfere with your loading space. There is no better van for fitting everything in on your moving day.`,

  INFO_LENGTH1: "Length: 4.0 m / 13.1 ft",
  INFO_LENGTH2: "Width: 2.0 m / 6.5 ft",
  INFO_LENGTH3: "Height: 2.2 m / 7.2 ft",
  INFO_LENGTH4: "Payload: 1200-1600 kg",
  INFO_LENGTH5: "Seats (inc driver): 3",
  XTRA_LARGE_VAN_TITLE:
    "Typical XLWB  (Extra Long Wheel Base) Van Load Space Specifications - Book today",
  XTRA_LARGE_VAN_DESCRIPTION: `The Extra-Large Van is ideal for more substantial removals. It can comfortably accommodate the contents of an average-sized 1-2 bedroom house or office. This includes larger pieces of furniture like sofas, double beds, and wardrobes, as well as appliances such as washing machines and fridge-freezers. The van can also hold between 45-60 standard boxes or approximately 40-45 large suitcases.`,
  XTRA_LARGE_VAN_LENGTH1: "Length: 4.50m / 14.76 ft",
  XTRA_LARGE_VAN_LENGTH2: "Width: 1.7m / 6.23ft",
  XTRA_LARGE_VAN_LENGTH3: "Height: 1.90m / 6.23ft",
  XTRA_LARGE_VAN_LENGTH4: "Payload: 1200-1500 kg",
  XTRA_LARGE_VAN_LENGTH5: "Seats (inc driver): 3",
  LARGE_VAN_TITLE:
    "Typical Large (Long Wheel Base) Van Load Space Specifications - Book today",
  LARGE_VAN_DESCRIPTION: `The LWB High Top Van offers a larger internal load space than any other panel van on the road and comes with a side loading door allowing for easy loading and unloading in tight locations, making this Van perfect for 1 -2 bedroom flat moves, business to business deliveries, removal service and local storage/store collections. By far the most popular van in the Man and Van services industry and used by removals companies everywhere.`,
  LARGE_VAN_LENGTH1: "Length: 3.4m / 11.1 ft",
  LARGE_VAN_LENGTH2: "Width: 1.7m / 5ft",
  LARGE_VAN_LENGTH3: "Height: 1.7m / 5ft",
  LARGE_VAN_LENGTH4: "Payload: 1200-1500 kg",
  LARGE_VAN_LENGTH5: "Seats (inc driver): 3",
  MEDIUM_VAN_TITLE:
    "Typical Medium (Medium Wheel Base) Van Load Space Specifications - Book today",
  MEDIUM_VAN_DESCRIPTION: `The Medium Van offers a usefully Large load area without being much longer or wider than a large car. The perfect solution for transporting two peoples belongings, pieces of furniture or sofa collections.`,
  MEDIUM_LENGTH1: "Length: 2.4 m / 7.8 ft",
  MEDIUM_LENGTH2: "Width: 1.7 m / 5 ft",
  MEDIUM_LENGTH3: "Height: 1.4 m / 4 ft",
  MEDIUM_LENGTH4: "Payload: 1200 kg",
  MEDIUM_LENGTH5: "Seats (inc driver): 3",
  SMALL_VAN_TITLE:
    "Typical Small (Small Wheel Base) Van Load Space Specifications - Book today",
  SMALL_VAN_DESCRIPTION: `The Small Van is most commonly used for luggage and parcel transfers, small vans can hold up to 8 packed suitcases. This is the perfect van hire for a solo individual being the perfect van size for 1 person's luggage and easy parking.
`,
  SMALL_LENGTH1: "Length: 1.2m / 3.5 ft",
  SMALL_LENGTH2: "Width: 1.49 m / 4.8 ft",
  SMALL_LENGTH3: "Height: 1.2 m / 3.9 ft",
  SMALL_LENGTH4: "Payload: 600-900 kg",
  SMALL_LENGTH5: "Seats (inc driver): 2",
  CLEAR_ALL: "Clear All",
  CLEAR_NOTIFICATION_SUCCUSS: "Clear notification successfully",
  AMOUNT_REFUNDED: "Amount has refunded",
  AMOUNT_NOT_REFUNDED: "Amount not refunded",
  FILE_SIZE_LARGE: "File size should be less than 5 MB.",
  CLEAR_ALL_NOTIFICATION: "Are you sure you want to clear all notifications?",
  NEW_LOCATION: "New Location",
  EXP_DATE_REQUIRED: "Expire date is required",
  SUBTEXT_SUPPORT: "What best describes the area you need help with?",
  SELECT_LOCATION_TEXT: "Select location",
  ABOVE_8TH_FLOOR: "Above 8th floor",
};

import { useTranslation } from "react-i18next";
import styles from "../Footer/Footer.module.css";
import { Container } from "react-bootstrap";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import logo from "../../assets/Images/footetImg.png";
import facebook from "../../assets/Images/faceBook.png";
import twitter from "../../assets/Images/twitter.png";
import pinrest from "../../assets/Images/pinrest.png";
import linkedin from "../../assets/Images/linkedin.png";
import playStore from "../../assets/Images/playStore.png";
import appStore from "../../assets/Images/appStore.png";
import phone from "../../assets/Images/phone.png";
import locationImg from "../../assets/Images/locationImg.png";
import gmail from "../../assets/Images/gmail.png";
import { FooterData } from "../../mockData";

//-------Create a component for footer--------
function Footer() {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  function formatCopyrightYear() {
    const currentYear = new Date().getFullYear();
    const formattedYearRange =
      currentYear + "-" + (currentYear + 1).toString().slice(-2);
    return formattedYearRange;
  }

  const handleInfoClick = (link, type) => {
    navigate(link, { state: type });
  };

  return (
    <>
      {/* {location.pathname == "/driver-login" ||
      location.pathname == "/driver-sign-up" ||
      location.pathname == "/driver-forgot-password" ||
      location.pathname == "/driver-reset-password" ? (
        ""
      ) : ( */}
      <section className={styles.footerSection}>
        <div className={styles.footerManage}>
          <Container>
            {/* <div className={styles.displayContent}>
                                <NavLink to="/">
                                    <img src={logo} alt="logo-image" />
                                </NavLink>
                                <div className={styles.displayLink}>
                                    {FooterData.map((item, index) => (
                                        <NavLink to={item.link} key={index} >
                                            {item.text}
                                        </NavLink>
                                    ))}
                                </div>
                            </div> */}
            <div className={styles.footerWrapper}>
              <div className={styles.footerInfo}>
                <div className={styles.footerItems}>
                  <div className={styles.footerHeading}>
                    <div className={styles.verticalDivider}></div>
                    <div>Contact Us</div>
                  </div>
                  <div className={styles.infoSection}>
                    <div>
                      <img src={locationImg} />
                    </div>
                    <div>9 Goldhawk Road, London W12 8QQ</div>
                  </div>
                  <div className={styles.infoSection}>
                    <div>
                      <img src={phone} />
                    </div>
                    <div>+44 333 335 6525</div>
                  </div>
                  <div className={styles.infoSection}>
                    <div>
                      <img src={gmail} />
                    </div>
                    <div>info@city2city.co.uk</div>
                  </div>
                </div>
                <div className={styles.footerItems}>
                  <div className={styles.footerHeading}>
                    <div className={styles.verticalDivider}></div>
                    <div>Services</div>
                  </div>
                  <div>Furniture and other items</div>
                  <div>Home removals</div>
                  <div>Business to Business</div>
                </div>
                <div className={styles.footerItems}>
                  <div className={styles.footerHeading}>
                    <div className={styles.verticalDivider}></div>
                    <div>Social Media</div>
                  </div>
                  <div className={styles.footerSocialInfo}>
                    <div>
                      <a
                        href={process.env.REACT_APP_FACEBOOK_LINK}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={facebook} alt="facebook" />
                      </a>
                    </div>
                    <div>
                      <a
                        href={process.env.REACT_APP_TWITTER_LINK}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={twitter} alt="Twitter" />
                      </a>
                    </div>
                    <div>
                      <a
                        href={process.env.REACT_APP_PINTEREST_LINK}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={pinrest} alt="Pinterest" />
                      </a>
                    </div>
                    <div>
                      <a
                        href={process.env.REACT_APP_LINKEDIN_LINK}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={linkedin} alt="LinkedIn" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className={styles.footerItems}>
                  <div className={styles.footerHeading}>
                    <div className={styles.verticalDivider}></div>
                    <div>Customer App</div>
                  </div>
                  <div
                    className={`${styles.footerSocialInfo} ${styles.footerSocialInfoStore}`}
                  >
                    <div>
                      <a
                        href={process.env.REACT_APP_CUSTOMER_GOOGLE}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={playStore} alt="facebook" />
                      </a>
                    </div>
                    <div>
                      <a
                        href={process.env.REACT_APP_CUSTOMER_APPLE}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img src={appStore} alt="facebook" />
                      </a>
                    </div>
                  </div>
                  <div className={styles.footerItems}>
                    <div className={styles.footerHeading}>
                      <div className={styles.verticalDivider}></div>
                      <div>Driver App</div>
                    </div>
                    <div
                      className={`${styles.footerSocialInfo} ${styles.footerSocialInfoStore}`}
                    >
                      <div>
                        <a
                          href={process.env.REACT_APP_DRIVER_GOOGLE}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={playStore} alt="facebook" />
                        </a>
                      </div>
                      <div>
                        <a
                          href={process.env.REACT_APP_DRIVER_APPLE}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={appStore} alt="facebook" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
        <div className={styles.copyText}>
          <div>
            {t("COPYRIGHT")} &copy; {t("City2City")} {formatCopyrightYear()}
          </div>
          <div className={styles.footerPolicy}>
            {FooterData.map((val) => {
              return (
                <div
                  className={styles.policyText}
                  onClick={() => handleInfoClick(val.link, val.type)}
                >
                  {val.text}
                </div>
              );
            })}
          </div>
        </div>
      </section>
      {/* )} */}
    </>
  );
}
export default Footer;

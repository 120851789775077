import axios from "axios";
import { DEVICE_TYPE } from "../utils/Constants";

const BASE_URL = process.env.REACT_APP_BASE_URL;
// const REACT_PROXYURL = "https://quiet-retreat-79741.herokuapp.com/";
const REACT_PROXYURL = "";

// for making unique id for every browser
if (!localStorage.getItem(process.env.REACT_APP_DEVICE_ID)) {
  const uint32 = window.crypto.getRandomValues(new Uint32Array(1))[0];
  localStorage.setItem(process.env.REACT_APP_DEVICE_ID, uint32.toString(32));
}

// for making unique token for every browser
if (!localStorage.getItem(process.env.REACT_APP_DEVICE_TOKEN)) {
  const uint32 = window.crypto.getRandomValues(new Uint32Array(1))[0];
  localStorage.setItem(process.env.REACT_APP_DEVICE_TOKEN, uint32.toString(32));
}

/** API Class
 * static clase trying together method used to get/send to the API.
 * There shouldn't be any frontend-specific stuff here, and there  shouldn't be any API-aware stuff else hre in the frontend...
 */
class SublyApi {
  //token which interact with the API will be stored here.
  static token;

  //required common header for each api calling.
  static commonHeaders = {
    "api-key": process.env.REACT_APP_API_KEY_PAIR,
    // "device-token": localStorage.getItem(process.env.REACT_APP_FIREBASE_TOKEN)
    //   ? localStorage.getItem(process.env.REACT_APP_FIREBASE_TOKEN)
    //   : localStorage.getItem(process.env.REACT_APP_DEVICE_TOKEN),
    "device-token": localStorage.getItem(process.env.REACT_APP_DEVICE_TOKEN),
    "device-id": localStorage.getItem(process.env.REACT_APP_DEVICE_ID),
    "device-type": DEVICE_TYPE.DEVICE_TYPE,
  };

  static async removeExtraSlashes(url) {
    // Replace multiple consecutive forward slashes with a single forward slash
    // return url.replace(/\/\/+/g, '/');
    return url.replace(/([^:]\/)\/+/g, "$1");
  }

  static async request(endpoint, data = {}, method = "get", header) {
    const url = await this.removeExtraSlashes(
      `${REACT_PROXYURL}${BASE_URL}${endpoint}`
    );
    // const url = `${REACT_PROXYURL}${BASE_URL}${endpoint}`;
    const headers = { ...SublyApi.commonHeaders, ...header };
    const params = method === "get" ? data : {};
    try {
      const result = await axios({ url, method, data, params, headers });
      return result ? result.data : "There is no return for this route";
    } catch (err) {
      return err.response;
    }
  }

  /******************************************************
   * USERS AUTH API ROUTES
   *******************************************************/

  /* ------SIGNUP API -----*/
  static async signUp(data) {
    let res = await this.request(`/customer-api/v1/signup`, data, "post");
    return res;
  }

  /* ------LOGIN API -----*/
  static async login(data) {
    let res = await this.request(`/customer-api/v1/login`, data, "post");
    return res;
  }

  /* ------FORGOT PASSWORD API -----*/
  static async forgotPassword(data) {
    let res = await this.request(
      `/customer-api/v1/forgot-password`,
      data,
      "patch"
    );
    return res;
  }

  /* -----UPDATE PASSWORD API -----*/
  static async updatePassword(data) {
    let res = await this.request(
      `/customer-api/v1/update-password`,
      data,
      "patch"
    );
    return res;
  }

  /* -----FETCH CATEGORY LIST -----*/
  static async fetchCategoryList() {
    let res = await this.request(
      `/customer-api/v1/category-list?is_level=true`,
      {},
      "get"
    );
    return res;
  }

  /* -----FETCH MASTER DATA LIST -----*/
  static async fetchMasterDataList(refKey) {
    let res = await this.request(
      `/customer-api/v1/master-data-list?ref_key=${refKey}`,
      {},
      "get"
    );
    return res;
  }

  /* ----- FETCH SETTINGS-OPTIONS LIST -----*/
  static async fetchSettingsOptionsList(type) {
    let res = await this.request(
      `/customer-api/v1/setting-options?type=${type}`,
      {},
      "get"
    );
    return res;
  }

  /* ----- SAVE B2B ENQUIRY DATA -----*/
  static async saveB2bEnquiryData(data) {
    let res = await this.request(
      `/customer-api/v1/save-b2b-enquiry`,
      data,
      "post"
    );
    return res;
  }

  /* -----FETCH FLOOR OPTIONS -----*/
  static async fetchFloorOptions() {
    let res = await this.request(
      `/customer-api/v1/master-data-list?ref_key=floors`,
      {},
      "get"
    );
    return res;
  }

  /* -----FETCH VEHICLE INFO LIST -----*/
  static async fetchVehicleInfoList() {
    let res = await this.request(
      `/customer-api/v1/vehicle-info-list`,
      {},
      "get"
    );
    return res;
  }

  /* -----FETCH HELPERS LIST -----*/
  static async fetchHelpersList() {
    let res = await this.request(
      `/customer-api/v1/master-data-list?ref_key=helpers`,
      {},
      "get"
    );
    return res;
  }

  /* -----FETCH UNIT TYPE LIST -----*/
  static async fetchUnitTypes() {
    let res = await this.request(
      `/customer-api/v1/master-data-list?ref_key=weight_units,dimension_units`,
      {},
      "get"
    );
    return res;
  }

  /* -----FETCH COUPONS LIST -----*/
  static async fetchCouponsList(authToken) {
    let res = await this.request(`/customer-api/v1/coupon-list`, {}, "get");
    return res;
  }

  /* -----VALIDATE COUPON -----*/
  static async validateCoupon(authToken, userId, code) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/customer-api/v1/check-valid-coupon?coupon_code=${code}&user_id=${userId}`,
      {},
      "get",
      header
    );
    return res;
  }

  /* -----GET DRIVERS LIST -----*/
  static async getDriversList(data) {
    let res = await this.request(`/customer-api/v1/driver-list`, data, "post");
    return res;
  }

  /* -----ACCEPT JOB API -----*/
  static async acceptJob(data, authToken) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/customer-api/v1/accept-job`,
      data,
      "post",
      header
    );
    return res;
  }

  /* -----CREATE PAYMENT INTENT API -----*/
  static async createPaymentIntent(data, authToken) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/customer-api/v1/create-payment-intent`,
      data,
      "post",
      header
    );
    return res;
  }

  /* -----SAVE JOB API -----*/
  static async saveJob(data, authToken) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/customer-api/v1/save-job`,
      data,
      "post",
      header
    );
    return res;
  }

  /* -----GET JOB LIST API -----*/
  static async getJobList(data, authToken) {
    const params = new URLSearchParams(data);
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/customer-api/v1/job-list?${params.toString()}`,
      {},
      "get",
      header
    );
    return res;
  }

  /* -----FETCH JOB DETAILS API -----*/
  static async getJobDetails(jobId, authToken) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/customer-api/v1/job-details/${jobId}`,
      {},
      "get",
      header
    );
    return res;
  }

  /* -----TRACK JOB STATUS API -----*/
  static async trackJobStatus(jobId, authToken) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/customer-api/v1/track-job?job_id=${jobId}`,
      {},
      "get",
      header
    );
    return res;
  }

  /* -----FETCH RATING LIST DATA API -----*/
  static async fetchRatingListData() {
    let res = await this.request(`/customer-api/v1/rating-list`, {}, "get");
    return res;
  }

  /* -----DELETE CUSTOMER ACCOUNT-----*/
  static async deleteCustomerAccount(authToken) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/customer-api/v1/delete-account`,
      undefined,
      "delete",
      header
    );
    return res;
  }

  /* -----GIVE REVIEW AND RATING DRIVER-----*/
  static async giveReviewRating(authToken, postData) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/customer-api/v1/give-review-rating`,
      postData,
      "post",
      header
    );
    return res;
  }

  // -------------------------driver side api calling---------------------------------

  /* -----VEHICLE DATA LIST -----*/
  static async vehicleDataList() {
    let res = await this.request(`/driver-api/v1/vehicle-info-list`, "", "get");
    return res;
  }

  /* ------MASTER DATALIST API -----*/
  static async masterDataList(ref_key) {
    let res = await this.request(
      `/driver-api/v1/master-data-list?ref_key=${ref_key}`,
      undefined,
      "get"
    );
    return res;
  }

  /* ------DRIVER SIGNUP API -----*/
  static async driverSignUp(data) {
    let res = await this.request(`/driver-api/v1/signup`, data, "post");
    return res;
  }

  /* ------DRIVER LOGIN API -----*/
  static async driverLogin(data) {
    let res = await this.request(`/driver-api/v1/login`, data, "post");
    return res;
  }

  /* ------DRIVER FORGOT PASSWORD API -----*/
  static async driverForgotPassword(data) {
    let res = await this.request(
      `/driver-api/v1/forgot-password`,
      data,
      "patch"
    );
    return res;
  }

  /* -----DRIVER UPDATE PASSWORD API -----*/
  static async driverUpdatePassword(data) {
    let res = await this.request(
      `/driver-api/v1/update-password`,
      data,
      "patch"
    );
    return res;
  }

  /* -----DRIVER GET DOCUMENT -----*/
  static async getDriverDocument(authToken) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(`/driver-api/v1/document`, "", "get", header);
    return res;
  }

  /* -----DRIVER UPDATE DOCUMENT -----*/
  static async updateDriverDocument(data, authToken) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/driver-api/v1/document`,
      data,
      "post",
      header
    );
    return res;
  }

  /* -----DRIVER GET PROFILE DETAILS -----*/
  static async getDriverDetails(authToken) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(`/driver-api/v1/profile`, "", "get", header);
    return res;
  }

  /* -----DRIVER UPDATE PROFILE DETAILS -----*/
  static async driverDetailsUpdate(data, authToken) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(`/driver-api/v1/profile`, data, "put", header);
    return res;
  }

  /* -----GET VEHICLE LIST -----*/
  static async getVehicleList(authToken, page_no) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/driver-api/v1/vehicle?page_no=${page_no}`,
      "",
      "get",
      header
    );
    return res;
  }

  /* -----ADD/EDIT VEHICLE-----*/
  static async addVehicle(data, authToken) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/driver-api/v1/vehicle`,
      data,
      "post",
      header
    );
    return res;
  }

  // ------------DELETE VEHICLE-----------
  static async deleteVehicle(authToken, driver_vehicle_id) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/driver-api/v1/vehicle/${driver_vehicle_id}`,
      undefined,
      "delete",
      header
    );
    return res;
  }
  // ------------DRIVER CHANGE PASSWORD-----------
  static async driverChangePassword(data, authToken) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `driver-api/v1/change-password`,
      data,
      "put",
      header
    );
    return res;
  }

  /* -----GET CUSTOMER DETAILS -----*/
  static async getUserDetails(authToken) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(`/customer-api/v1/profile`, "", "get", header);
    return res;
  }

  /* -----UPDATE CUSTOMER PROFILE DETAILS -----*/
  static async userDetailsUpdate(data, authToken) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/customer-api/v1/profile`,
      data,
      "put",
      header
    );
    return res;
  }

  /* -----UPDATE DRIVER VEHICLE PRICE-----*/
  static async driverPriceSet(data, authToken) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/driver-api/v1/save-price`,
      data,
      "post",
      header
    );
    return res;
  }

  /* -----GET DRIVER VEHICLE PRICE LIST-----*/
  static async getDriverPriceList(authToken, vehicle_type_id) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/driver-api/v1/price-setting-list?vehicle_type_id=${vehicle_type_id}`,
      "",
      "get",
      header
    );
    return res;
  }

  /* -----SET DRIVER RADIUS-----*/
  static async setRadius(data, authToken) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(`/driver-api/v1/radius`, data, "put", header);
    return res;
  }

  /* -----GET RADIUS VALUE-----*/
  static async getRadius(authToken) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(`/driver-api/v1/radius`, "", "get", header);
    return res;
  }

  /* -----DELETE DRIVER ACCOUNT-----*/
  static async deleteAccount(authToken) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/driver-api/v1/delete-account`,
      undefined,
      "delete",
      header
    );
    return res;
  }

  /* -----LOGOUT DRIVER ACCOUNT-----*/
  static async logoutAccount(authToken) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/driver-api/v1/logout-account`,
      "",
      "put",
      header
    );
    return res;
  }

  /* -----GET DRIVER CATEGORY LIST -----*/
  static async getCategoryList() {
    let res = await this.request(`/driver-api/v1/category-list`, "", "get");
    return res;
  }

  /* -----ADD/UPDATE SERVICE LIST-----*/
  static async UpdateService(data, authToken) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/driver-api/v1/service`,
      data,
      "post",
      header
    );
    return res;
  }

  /* -----GET SERVICE LIST-----*/
  static async getServiceList(authToken) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(`/driver-api/v1/service`, "", "get", header);
    return res;
  }

  /* -----GET SERVICE SETTING LIST-----*/
  static async getServiceSettingList(authToken) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/driver-api/v1/service?other_service=true`,
      "",
      "get",
      header
    );
    return res;
  }

  /* -----UPDATE SERVICE SETTING LIST-----*/
  static async updateServiceSettingList(authToken, status_type) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/driver-api/v1/update-driver-service-status?status_type=${status_type}`,
      "",
      "put",
      header
    );
    return res;
  }

  /* -----GET DRIVER SCEDULE LIST-----*/
  static async getScheduleList(authToken) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/driver-api/v1/schedule-list`,
      "",
      "get",
      header
    );
    return res;
  }

  /* -----ADD/UPDATE DRIVER SCEDULE LIST-----*/
  static async UpdateScheduleList(data, authToken) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/driver-api/v1/save-schedule`,
      data,
      "post",
      header
    );
    return res;
  }

  /* -----GET JOB LIST API -----*/
  static async getDriversJobList(data, authToken) {
    const params = new URLSearchParams(data);
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/driver-api/v1/job-list?${params.toString()}`,
      {},
      "get",
      header
    );
    return res;
  }

  /* -----FETCH JOB DETAILS API -----*/
  static async getDriversJobDetails(jobId, authToken) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/driver-api/v1/job-details/${jobId}`,
      {},
      "get",
      header
    );
    return res;
  }

  // ------------CUSTOMER CHANGE PASSWORD-----------
  static async CoustomerChangePassword(data, authToken) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/customer-api/v1/change-password`,
      data,
      "put",
      header
    );
    return res;
  }

  /* -----CHANGE JOB REQUEST STATUS API -----*/
  static async changeRequestJobStatus(jobId, authToken, requestData) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/driver-api/v1/job-request-status/${jobId}`,
      requestData,
      "put",
      header
    );
    return res;
  }

  // ------------CUSTOMER SUPPORT-----------
  static async CoustomerSupport(data, authToken) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/customer-api/v1/support`,
      data,
      "post",
      header
    );
    return res;
  }

  /* -----GET DRIVER SUPPORT LIST-----*/
  static async getDriverSupportList(authToken, parent_id, is_level) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/driver-api/v1/support-category-list?parent_id=${parent_id}&is_level=${is_level}`,
      "",
      "get",
      header
    );
    return res;
  }

  /* -----GET CUSTOMER SUPPORT LIST-----*/
  static async getCustomerSupportList(authToken, parent_id, is_level) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/customer-api/v1/support-category-list?parent_id=${parent_id}&is_level=${is_level}`,
      "",
      "get",
      header
    );
    return res;
  }

  // ------------POST CUSTOMER CONTACT SUPPORT-----------
  static async customerContactSupport(data, authToken) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/customer-api/v1/support`,
      data,
      "post",
      header
    );
    return res;
  }
  // ------------POST DRIVER CONTACT SUPPORT-----------
  static async ContactSupport(data, authToken) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/driver-api/v1/save-contact-support`,
      data,
      "post",
      header
    );
    return res;
  }

  /* ---------GET-TUTORIAL------------*/
  static async getDriverTutorial(authToken, category_type) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/driver-api/v1/fetch-tutorial?category_type=${category_type}`,
      {},
      "get",
      header
    );
    return res;
  }

  /* ---------GET-SETTING-OPTIONS------------*/
  static async getCustomerSettingList(authToken, type, option_name) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/customer-api/v1/setting-options?type=${type}&option_name=${option_name}`,
      {},
      "get",
      header
    );
    return res;
  }

  /* -------Driver--GET-SETTING-OPTIONS------------*/
  static async getDriverSettingList(authToken, type, option_name) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/driver-api/v1/setting-options?type=${type}&option_name=${option_name}`,
      {},
      "get",
      header
    );
    return res;
  }

  /* ------Get Customer Notification API -----*/
  static async getNotification(authToken, pageNumber) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/customer-api/v1/notification-list?page_no=${pageNumber}`,
      "",
      "get",
      header
    );
    return res;
  }

  /* ------ Customer Read Notification Api-----*/
  static async customerReadNotification(authToken, id) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/customer-api/v1/read-notification?notification_id=${id}`,
      "",
      "get",
      header
    );
    return res;
  }

  /* ------Get Driver Notification API -----*/
  static async getDriverNotification(authToken, pageNumber) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/driver-api/v1/notification-list?page_no=${pageNumber}`,
      "",
      "get",
      header
    );
    return res;
  }

  /* ------ Driver Read Notification Api-----*/
  static async DriverReadNotification(authToken, id) {
    let header = { "access-token": ` ${authToken}` };
    let res = await this.request(
      `/driver-api/v1/read-notification?notification_id=${id}`,
      "",
      "get",
      header
    );
    return res;
  }

  /* -----CUSTOMER CANCEL JOB API -----*/
  static async customerCancelJob(jobId, authToken) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/customer-api/v1/cancel-job/${jobId}`,
      {},
      "patch",
      header
    );
    return res;
  }

  /* -----FETCH CUSTOMERS FAQ API -----*/
  static async fetchCustomersFaq() {
    let res = await this.request(`/customer-api/v1/faq-list`, {}, "get");
    return res;
  }

  /* -----FETCH Driver bank information API -----*/
  static async fetchDriverBankInfo(authToken) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/driver-api/v1/fetch-bank-info`,
      {},
      "get",
      header
    );
    return res;
  }

  /* -----Update Driver bank information API -----*/
  static async updateDriverBankInfo(data, authToken) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/driver-api/v1/update-bank-info`,
      data,
      "patch",
      header
    );
    return res;
  }

  /* -----Update Driver bank information API -----*/
  static async driverEmailCheck(data, authToken) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/driver-api/v1/phone-number-email-existance`,
      data,
      "post",
      header
    );
    return res;
  }

  /* ------Notification clear-----*/
  static async clearNotification(authToken, id) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/customer-api/v1/clear-all-notification/${id}`,
      {},
      "delete",
      header
    );
    return res;
  }

  /* ------Notification clear-----*/
  static async clearNotificationDriver(authToken, id) {
    let header = { "access-token": `${authToken}` };
    let res = await this.request(
      `/driver-api/v1/clear-all-notification/${id}`,
      {},
      "delete",
      header
    );
    return res;
  }
}

export default SublyApi;

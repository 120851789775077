import Modal from "react-bootstrap/Modal";
import { IoMdCloseCircle } from "react-icons/io";
import { Button, Col, Form, Row } from "react-bootstrap";
import styles from "./modal.module.css";
import { useEffect, useState } from "react";
import SublyApi from "../../../../helpers/Api";
import { useSelector } from "react-redux";
import { STATUS_CODES } from "../../../../utils/StatusCode";
import { useTranslation } from "react-i18next";
import Toster from "../../../../utils/Toaster";
import { STATUS_MSG } from "../../../../utils/StatusMsg";
import offerIcon from "../../../../assets/Images/offerIcon.png";
import Loader from "../../../../utils/Loader/Loader";
import dayjs from "dayjs";
import { Icon } from "@iconify/react/dist/iconify.js";

export default function AddCoupon({
  showCoupon,
  setShowCoupon,
  setSelectedCoupon,
}) {
  const { t } = useTranslation();
  const { userToken } = useSelector((state) => state.user);
  const userData = useSelector((state) => state.user);
  const userId = userData?.currentUser?.id || "";

  const [options, setOptions] = useState();
  const [filteredOptions, setFilteredOptions] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [searchKey, setSearchKey] = useState("");

  const getCouponsList = () => {
    setIsLoading(true);
    let res = SublyApi.fetchCouponsList();
    res.then((response) => {
      const responseMsg = response && response.data && response.data.message;
      const responseCode =
        STATUS_MSG[response && response.data && response.data.code];
      setIsLoading(false);
      if (response.code === STATUS_CODES.SUCCESS) {
        setOptions(response.data.couponList);
        setFilteredOptions(response.data.couponList);
      } else {
        if (
          response.code == STATUS_CODES.SERVER_VALIDATION ||
          response.data.code == STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(responseMsg, "error");
        } else {
          Toster(t(responseCode), "error");
        }
      }
    });
    res.catch(() => {
      setIsLoading(false);
    });
  };

  const handleValidateCoupon = (code) => {
    setIsLoading(true);
    let res = SublyApi.validateCoupon(userToken || "", userId, code);
    res.then((response) => {
      const responseMsg = response && response.data && response.data.message;
      const responseCode =
        STATUS_MSG[response && response.data && response.data.code];
      setIsLoading(false);
      if (response.code === STATUS_CODES.SUCCESS) {
        handleApplyCode(response.data);
      } else {
        if (
          response.code == STATUS_CODES.SERVER_VALIDATION ||
          response.data.code == STATUS_CODES.SERVER_VALIDATION
        ) {
          Toster(responseMsg, "error");
        } else {
          Toster(t(responseCode), "error");
        }
      }
    });
    res.catch(() => {
      setIsLoading(false);
    });
  };

  useEffect(() => {
    showCoupon && getCouponsList();
    setSearchKey("");
  }, [showCoupon]);

  const handleSearchCoupon = (key) => {
    const trimKey = key?.toUpperCase()?.trimStart();
    setSearchKey(trimKey);
    if (key == "") {
      setFilteredOptions(options);
    }
    const filtered = options.filter((option) => {
      return option.coupon_code.toLowerCase().includes(trimKey.toLowerCase());
    });
    setFilteredOptions(filtered);
  };

  const handleClose = () => {
    setShowCoupon(false);
  };

  const handleApplyCode = (coupon) => {
    setSelectedCoupon(coupon);
    handleClose();
  };

  return (
    <>
      {isLoading && <Loader />}
      <Modal show={showCoupon} onHide={() => handleClose()}>
        <div className="selectModal couponModal">
          <Modal.Header>
            <Modal.Title>{t("PROMO")}</Modal.Title>
            <IoMdCloseCircle
              onClick={() => {
                handleClose();
              }}
            />
          </Modal.Header>
          <Modal.Body>
            <Row className={styles.couponTopField}>
              <Col>
                <Form.Group className="formOrderSection fifthTab">
                  <Form.Control
                    type="search"
                    value={searchKey}
                    className=""
                    onChange={(e) => handleSearchCoupon(e.target.value)}
                    placeholder={t("SEARCH_PROMO")}
                  />
                  {!searchKey && (
                    <Icon
                      icon="iconoir:search"
                      color="#0F7DFF"
                      width="22"
                      height="22"
                    />
                  )}
                </Form.Group>
              </Col>
              {searchKey && (
                <Col lg={3} md={3} sm={3} xs={3}>
                  <Button
                    style={{ float: "right" }}
                    onClick={() => handleValidateCoupon(searchKey)}
                  >
                    {t("APPLY")}
                  </Button>
                </Col>
              )}
            </Row>
            <Row>
              {filteredOptions?.map((coupon) => {
                return (
                  <Col lg={12} className="mb-3">
                    <div className={styles.couponContainer}>
                      <div className={styles.couponInfo}>
                        <div>
                          <img src={offerIcon} alt="offerIcon" />
                        </div>
                        <div>
                          <div className={styles.offValue}>
                            {coupon.coupon_code}
                          </div>
                          <div className={styles.couponTitle}>
                            {coupon.coupon_desc}
                          </div>
                          <div className={styles.couponValid}>
                            {t("VALID_DATE")} :{" "}
                            {dayjs(coupon.expire_date).format("DD/MM/YYYY")}
                          </div>
                        </div>
                      </div>
                      <div className={styles.couponApply}>
                        <Button
                          onClick={() =>
                            handleValidateCoupon(coupon.coupon_code)
                          }
                        >
                          {t("APPLY")}
                        </Button>
                      </div>
                    </div>
                  </Col>
                );
              })}
            </Row>
            {/* {filteredOptions?.length == 0 && (
              <div>Does not found any results.!</div>
            )} */}
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
}
